import CONTRACT_ABI from "../contract/usdcTokenContract.json";
import { ethers } from "ethers";

const contractAddress = "0xe3dAE74a8AEb8d164372E93C02d4f3C95472c3fc";

export const getBalance = async (address) => {
  if (window.ethereum) {
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(contractAddress, CONTRACT_ABI, signer);
    const balance = await contract.balanceOf(address.toString());
    const balanceInUsdc = Number(balance) / 1e18;

    return balanceInUsdc;
  }
};

export const approveToken = async (spenderAddress, amountToApprove) => {
  const { ethereum } = window;
  try {
    if (ethereum) {
      const provider = new ethers.BrowserProvider(ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        contractAddress,
        CONTRACT_ABI,
        signer
      );
      console.log("Initialize Payment...");
      const txn = await contract.approve(spenderAddress, amountToApprove);
      console.log("Approving tokens... please wait");
      await txn.wait();
      console.log(
        `Approve tokens, see transaction: https://mumbai.polygonscan.com/tx/${txn.hash}`
      );
      return true;
    } else {
      console.log("Ethereum object does not exist");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
