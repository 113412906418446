/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import {
  RESET_FILTERS,
  SET_CUSTOM_DATES_FILTER,
  SET_ENDS_THIS_MONTH_FILTER,
  SET_ENDS_THIS_WEEK_FILTER,
  SET_ENDS_TODAY_FILTER,
  SET_MIN_AND_MAX,
  SET_PERCENTAGE_FILTER,
} from "../constants/filters";

export const filtersReducer = (
  state = {
    min: "",
    max: "",
    percentageFilter: false,
    endsTody: false,
    endsThisWeek: false,
    endsThisMonth: false,
    custom: false,
    reset: false,
  },
  action
) => {
  switch (action.type) {
    case SET_MIN_AND_MAX: {
      return { ...state, min: action.payload.min, max: action.payload.max };
    }
    case SET_PERCENTAGE_FILTER: {
      return {
        ...state,
        endsTody: false,
        endsThisWeek: false,
        endsThisMonth: false,
        custom: false,
        percentageFilter: action.payload,
        reset: false,
      };
    }
    case SET_ENDS_TODAY_FILTER: {
      return {
        ...state,
        percentageFilter: false,
        endsThisWeek: false,
        endsThisMonth: false,
        custom: false,
        endsTody: action.payload,
        reset: false,
      };
    }
    case SET_ENDS_THIS_WEEK_FILTER: {
      return {
        ...state,
        percentageFilter: false,
        endsTody: false,
        endsThisMonth: false,
        custom: false,
        endsThisWeek: action.payload,
        reset: false,
      };
    }
    case SET_ENDS_THIS_MONTH_FILTER: {
      return {
        ...state,
        percentageFilter: false,
        endsTody: false,
        endsThisWeek: false,
        custom: false,
        endsThisMonth: action.payload,
        reset: false,
      };
    }
    case SET_CUSTOM_DATES_FILTER: {
      return {
        ...state,
        percentageFilter: false,
        endsTody: false,
        endsThisWeek: false,
        endsThisMonth: false,
        custom: action.payload,
        reset: false,
      };
    }
    case RESET_FILTERS: {
      return {
        ...state,
        percentageFilter: false,
        endsTody: false,
        endsThisWeek: false,
        endsThisMonth: false,
        custom: false,
        reset: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
