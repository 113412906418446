/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Classes from "../../scss/Main/PortalDeals.module.scss";
import { Tabs } from "antd";
import { Modal, DatePicker, Select } from "antd";
import DealCard from "../../../../partials/Common/DealCard";
import addIcon from "../../../../../assets/images/svg/addIcon.svg";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConnectWallet } from "../../../../../_actions/user_actions";
import btc from "../../../../../assets/images/svg/btc.svg";
import matic from "../../../../../assets/images/svg/MATIC.svg";
import dai from "../../../../../assets/images/svg/dai.svg";
import { getLatestPrice } from "../../../../../web3/cryptoDeals";
import {
  createDealAPI,
  getDealsListAPI,
  getFavoriteDealList,
} from "../../../../../_actions/deals_actions";
import { useFormik } from "formik";
import moment from "moment";
import { createDealSchema } from "../../../../../models/createDealSchema";
import { Bars } from "react-loader-spinner";
import {
  SET_CUSTOM_DATES_FILTER,
  SET_ENDS_THIS_MONTH_FILTER,
  SET_ENDS_THIS_WEEK_FILTER,
  SET_ENDS_TODAY_FILTER,
  SET_PERCENTAGE_FILTER,
} from "../../../../../constants/filters";

const polygonTestNetCoins = [
  {
    name: "BTC",
    address: "0x007A22900a3B98143368Bd5906f8E17e9867581b",
    image: btc,
  },
  {
    name: "MATIC",
    address: "0xd0D5e3DB44DE05E9F294BB0a3bEEaF030DE24Ada",
    image: matic,
  },
  {
    name: "DAI",
    address: "0x0FCAa9c899EC5A91eBc3D5Dd869De833b06fB046",
    image: dai,
  },
];

function PortalDeals() {
  const { TabPane } = Tabs;
  const userInfo = useSelector((state) => state.userInfo);
  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedCoinName, setSelectedCoinName] = useState("");
  const [selectedCoinAddress, setSelectedCoinAddress] = useState("");
  const [selectedCoinPrice, setSelectedCoinPrice] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [dealsList, setDealsList] = useState([]);
  const [claimDeals, setClaimDeals] = useState([]);
  const [activeDeals, setActiveDeals] = useState([]);
  const [favDeals, setFavDeals] = useState([]);
  const [text, setText] = useState("");
  const [loader, setLoader] = useState(false);
  const [maxPriceError, setMaxPriceError] = useState("");
  const [stopLossPriceError, setStopLossPriceError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showModal = () => {
    if (token && userInfo?.isConnected) {
      setIsModalOpen(true);
      setSelectedDate("");
      setDealsList([]);
    } else {
      setIsModalOpen2(true);
    }
  };
  // const handleOk = async () => {
  //   setIsModalOpen(false);
  //   dispatch(createDealAPI());
  // };
  // console.log(userInfo?.user?.wallet)

  const handleCancel = () => {
    setIsModalOpen(false);
    resetForm();
    setSelectedDate("")
    dispatch(getDealsListAPI(setDealsList));
  };
  const handleOk2 = (e) => {
    e.preventDefault();
    dispatch(ConnectWallet(userInfo?.user?._id, navigate, "", setIsModalOpen2));
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const { Option } = Select;
  const getCoinsLatestPrice = () => {
    const coins = [];
    polygonTestNetCoins.map(async (coin) => {
      const coinPrice = await getLatestPrice(coin.address);
      coins.push({
        value: coin.address,
        label: coin.name,
        imageUrl: coin.image,
        price: coinPrice + " USDC",
      });
    });
    return coins;
  };
  useEffect(() => {
    dispatch(getDealsListAPI(setDealsList));
    if (userInfo.isConnected) {
      setOptions(getCoinsLatestPrice());
    }
    dispatch(getFavoriteDealList(userInfo?.user?._id, setFavDeals));
  }, [dispatch, userInfo]);
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    errors,
    isValid,
  } = useFormik({
    onSubmit: async (values) => {
      const data = {
        coinName: selectedCoinName,
        coinAddress: selectedCoinAddress,
        targetPrice: values.maxPrice,
        stopLossPrice: values.stopLossPrice,
        entryPrice: selectedCoinPrice,
        dealEndTime: selectedDate,
        ownerId: userInfo?.user?._id,
        ownerAddress: userInfo?.user?.wallet,
      };
      dispatch(
        createDealAPI(
          data,
          resetForm,
          setIsModalOpen,
          setDealsList,
          setText,
          setLoader
        )
      );
      if ((text === "") & (loader === false)) {
        setSelectedDate("");
        dispatch(getDealsListAPI(setDealsList));
      }
    },
    initialValues: {
      maxPrice: "",
      stopLossPrice: "",
    },
    validationSchema: createDealSchema,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
  });
  const coinSelectHandler = (e) => {
    const selectedCoin = JSON.parse(e);
    setSelectedCoinName(selectedCoin.label);
    setSelectedCoinAddress(selectedCoin.value);
    setSelectedCoinPrice(selectedCoin.price);
  };
  const onChangedate = (date, dateString) => {
    setSelectedDate(new Date(dateString));
  };
  const onChange = (key) => {
    // console.log(key);
    if (key === "2") {
      const getActiveDeals = dealsList.filter(
        (deal) =>
          deal.participators.length > 0 &&
          moment(new Date()).isBefore(deal.dealEndTime)
      );
      setActiveDeals(getActiveDeals);
    }
    if (key === "3") {
      dispatch(getFavoriteDealList(userInfo?.user?._id, setFavDeals));
    }
    if (key === "4") {
      const claims = dealsList.filter(
        (deal) =>
          !moment(new Date()).isBefore(deal.dealEndTime) &&
          (deal.participators.map((joiner) => joiner === userInfo.user._id) ||
            deal.ownerId === userInfo.user._id)
      );
      setClaimDeals(claims);
    }
  };
  useEffect(() => {
    if (
      parseFloat(values.maxPrice) <= parseFloat(selectedCoinPrice.split(" ")[0])
    ) {
      setMaxPriceError("Max price should be greater then the current price");
    } 
    // else if (
    //   parseFloat(values.stopLossPrice) <
    //   parseFloat(selectedCoinPrice.split(" ")[0])
    // ) {
    //   setStopLossPriceError(
    //     "Stop loss price should be greater then or equal to the current price"
    //   );
    // } 
    else {
      setMaxPriceError("");
      setStopLossPriceError("");
    }
  }, [values.maxPrice, values.stopLossPrice]);

  const findProfitPercentage = (deal) => {
    const result = (
      [
        (parseFloat(deal?.targetPrice) -
          parseFloat(deal?.entryPrice?.split(" ")[0])) /
          parseFloat(deal?.entryPrice?.split(" ")[0]),
      ] * 100
    ).toFixed(1);
    return result;
  };

  const percentageFilter = () => {
    const dealsWithPercentage = dealsList.map((deal) => {
      const result = findProfitPercentage(deal);
      return { ...deal, profitPercentage: result };
    });
    const filteredWithRange = dealsWithPercentage.filter(
      (deal) =>
        parseInt(deal.profitPercentage) >= parseInt(filters.min) &&
        parseInt(deal.profitPercentage) <= parseInt(filters.max)
    );
    setDealsList(filteredWithRange);
    dispatch({
      type: SET_PERCENTAGE_FILTER,
      payload: false,
    });
  };

  const endTodayFilter = () => {
    const endsToday = dealsList.filter(
      (deal) => moment(deal.dealEndTime).diff(moment(), "days") === 0
    );
    setDealsList(endsToday);
    dispatch({
      type: SET_ENDS_TODAY_FILTER,
      payload: false,
    });
  };

  const endThisWeekFilter = () => {
    const endsThisWeek = dealsList.filter(
      (deal) => moment(deal.dealEndTime).diff(moment(), "days") === 7
    );
    setDealsList(endsThisWeek);
    dispatch({
      type: SET_ENDS_THIS_WEEK_FILTER,
      payload: false,
    });
  };

  const endThisMonthFilter = () => {
    const endsToday = dealsList.filter(
      (deal) => moment(deal.dealEndTime).diff(moment(), "days") >= 30
    );
    setDealsList(endsToday);
    dispatch({
      type: SET_ENDS_THIS_MONTH_FILTER,
      payload: false,
    });
  };

  const customDatesFilter = () => {
    const customDates = dealsList.filter(
      (deal) =>
        moment(deal.dealEndTime) >= moment(filters.min) &&
        moment(deal.dealEndTime) <= moment(filters.max)
    );
    setDealsList(customDates);
    dispatch({
      type: SET_CUSTOM_DATES_FILTER,
      payload: false,
    });
  };

  const filters = useSelector((state) => state.filters);

  useEffect(() => {
    if (filters.percentageFilter) {
      return percentageFilter();
    }
    if (filters.endsTody) {
      return endTodayFilter();
    }
    if (filters.endsThisWeek) {
      return endThisWeekFilter();
    }
    if (filters.endsThisMonth) {
      return endThisMonthFilter();
    }
    if (filters.custom) {
      customDatesFilter();
    }
    if (filters.reset) {
      dispatch(getDealsListAPI(setDealsList));
    }
  }, [filters]);

  return (
    <div className={Classes.dealsMain}>
      <div className="d-flex justify-content-between">
        <Tabs defaultActiveKey="1" onChange={onChange} className="w-100">
          <TabPane tab="Available Deals" key="1">
            <div className={Classes.dealsOuter}>
              {dealsList.filter((deal) => moment(new Date()).isBefore(deal.dealEndTime)).length ? (
                dealsList.filter((deal) => moment(new Date()).isBefore(deal.dealEndTime))
                  .reverse()
                  .map((deal, i) => (
                    <DealCard
                      dealDetail={deal}
                      setDealsList={setDealsList}
                      key={i + "availableDeals"}
                    />
                  ))
              ) : (
                <p className="blank-data">No Deals Found...</p>
              )}
            </div>
            {/* <button className="primaryBtn m-auto d-block">
                Sign Up to load moree
              </button> */}
          </TabPane>
          <TabPane tab="Active Deals" key="2">
            <div className={Classes.dealsOuter}>
              {activeDeals.length ? (
                activeDeals
                  .reverse()
                  .map((deal, i) => (
                    <DealCard
                      dealDetail={deal}
                      setDealsList={setDealsList}
                      key={i + "activeDeals"}
                    />
                  ))
              ) : (
                <p className="blank-data">No Deals Found...</p>
              )}
            </div>
          </TabPane>
          <TabPane tab="Favorites" key="3">
            <div className={Classes.dealsOuter}>
              {favDeals?.length ? (
                favDeals
                  ?.reverse()
                  .map((deal, i) => (
                    <DealCard
                      dealDetail={deal}
                      setDealsList={setDealsList}
                      key={i + "favDeals"}
                      setFavDeals={setFavDeals}
                    />
                  ))
              ) : (
                <p className="blank-data">No Deals Found...</p>
              )}
            </div>
          </TabPane>
          {/* <TabPane tab="History" key="4">
            <div className={Classes.dealsOuter}>
              <DealCard />
              <DealCard />
              <DealCard />
              <DealCard />
            </div>
          </TabPane> */}
          <TabPane tab="Claims" key="4">
            <div className={Classes.dealsOuter}>
              {claimDeals.length ? (
                claimDeals
                  .reverse()
                  .map((deal, i) => (
                    <DealCard dealDetail={deal} key={i + "claimDeals"} />
                  ))
              ) : (
                <p className="blank-data">No Deals Found...</p>
              )}
            </div>
          </TabPane>
          {/* <TabPane tab="Closed Deals" key="5">
            <div className={Classes.dealsOuter}>
              {dealsList.length ? (
                dealsList
                  .filter(
                    (deal) => !moment(new Date()).isBefore(deal.dealEndTime)
                  )
                  .map((deal, i) => (
                    <DealCard
                      dealDetail={deal}
                      setDealsList={setDealsList}
                      key={i + "closeDeals"}
                    />
                  ))
              ) : (
                <p className="blank-data">No Deals Found...</p>
              )}
            </div> */}
          {/* <button className="primaryBtn m-auto d-block">
                Sign Up to load moree
              </button> */}
          {/* </TabPane> */}
        </Tabs>
        <button
          onClick={showModal}
          className={`${Classes.createBtn} d-flex align-items-center  gap-2 primaryBtn`}
        >
          <img src={addIcon} alt="icon" />
          <span>Create Deal</span>
        </button>
      </div>
      <Modal
        title="Create Deal"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            disabled={
              selectedCoinAddress === "" ||
              selectedDate === "" ||
              stopLossPriceError !== "" ||
              maxPriceError !== "" ||
              !isValid ||
              loader
            }
            className="primaryBtn w-100 py-2 d-flex align-items-center justify-content-center gap-3"
            onClick={handleSubmit}
          >
            {text === "" ? "Create Deal" : text}
            {loader ? (
              <Bars
                height="20"
                width="20"
                color="white"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
            ) : (
              ""
            )}
          </Button>,
        ]}
      >
        <p className={`${Classes.desc} my-3`}>
          Once your deal is placed, your profit will be decided at the end of
          time.{" "}
        </p>
        <div>
          <div className={Classes.mgBotom}>
            <label className="mb-2">
              <b>Select your Deal</b>
            </label>
            <Select
              className="w-100"
              placeholder="Select"
              optionFilterProp="children"
              filterOption={(input, option) => option.label.includes(input)}
              onChange={(e) => coinSelectHandler(e)}
            >
              {options?.map((option) => (
                <Option key={option.value} value={JSON.stringify(option)}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center  gap-2">
                      <img className={Classes.optImg} src={option.imageUrl} />
                      <span className={Classes.optLabel}>{option.label}</span>
                    </div>
                    <span lassName={Classes.optPrice}>{option.price}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          <div className={Classes.mgBotom}>
            <label className="mb-2">
              <b>Maximum Price</b>
            </label>
            <input
              className={Classes.input}
              type="number"
              name="maxPrice"
              placeholder="Enter the maximum price"
              min={0}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.maxPrice}
            />
            {errors ? (
              <div className={Classes.error}>{errors.maxPrice}</div>
            ) : (
              ""
            )}
            {maxPriceError !== "" ? (
              <div className={Classes.error}>{maxPriceError}</div>
            ) : (
              ""
            )}
          </div>
          <div className={Classes.mgBotom}>
            <label className="mb-2">
              <b>Stop Loss Price</b>
            </label>
            <input
              className={Classes.input}
              type="number"
              name="stopLossPrice"
              placeholder="Enter the stop loss price"
              min={0}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.stopLossPrice}
            />
            {errors ? (
              <div className={Classes.error}>{errors.stopLossPrice}</div>
            ) : (
              ""
            )}
            {stopLossPriceError !== "" ? (
              <div className={Classes.error}>{stopLossPriceError}</div>
            ) : (
              ""
            )}
          </div>
          <div className={Classes.mgBotom}>
            <label className="mb-2">
              <b>Deal Limit </b>
              <span className={Classes.sm_size}>
                (Enter the number of days up to 365 days)
              </span>
            </label>
            <DatePicker
              disabledDate={(current) => {
                return (
                  moment().add(-1, "days") > current ||
                  moment().add(1, "year") < current
                );
              }}
              showTime
              showSecond={false}
              className={Classes.input}
              defaultValue={
                (selectedDate && moment(selectedDate, "YYYY-MM-DD")) || ""
              }
              onChange={onChangedate}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
          <p className={`${Classes.desc}`}>
            <b>Available Balance</b>
          </p>
          <p className={`${Classes.desc}`}>
            <b>{userInfo?.balance} USD</b>
          </p>
        </div>
      </Modal>
      <Modal
        title="Note"
        open={isModalOpen2}
        // onOk={handleOk}
        onCancel={handleCancel2}
        className={Classes.modalWidth}
        footer={[
          <Button
            className={`${Classes.modalFooter} primaryBtn w-100 py-2`}
            onClick={(e) => handleOk2(e)}
          >
            Connect
          </Button>,
        ]}
      >
        <p className={Classes.desc}>
          You need to connect your metamask account in order to create or join a
          deal on the platform.
        </p>
      </Modal>
    </div>
  );
}

export default PortalDeals;
