import React, { useEffect, useState } from "react";
import Classes from "../scss/Home/HeroSection.module.scss";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import { Button } from "react-bootstrap";
import cryptoImg from "../../../assets/images/views/Home/Cryptodron.svg";
import whiteCryptoImg from "../../../assets/images/views/Home/whiteCryptodron.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { SIGNUP_MODAL_CALL } from "../../../constants/user";

function HeroSection() {
  const [themeMode, setThemeMode] = useState("");
  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch()

  const signUpModalHandler = () => {
    dispatch({
      type: SIGNUP_MODAL_CALL,
      payload: true,
    });
  };

  useEffect(() => {
    setThemeMode(theme);
  }, [theme]);

  return (
    <div className={Classes.heroSection}>
      <Container>
        <Row>
          <Col md={12}>
            <div className={Classes.rightSection}>
              <h1 className={Classes.title}>
                Unlock Your Profit Potential with Precise Predictions!
              </h1>
              <p className={Classes.desc}>
                Enabling the human race to earn and make predictions with
                greater value to add.
              </p>
              <button className="primaryBtn" onClick={signUpModalHandler}>
                Sign Up
              </button>
            </div>
          </Col>
          <Col md={12} className={`${Classes.imgOuter}  d-flex justify-content-end`}>
            {themeMode === "dark-theme" ? (
              <img className={Classes.bitcoinImg} src={whiteCryptoImg} alt="img" />
            ) : (
              <img className={Classes.bitcoinImg} src={cryptoImg} alt="img" />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroSection;
