/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */

import { message } from "antd";
import axios from "axios";
import Axios from "../services/interceptor";
import {
  CONNECT_WALLET_FAIL,
  CONNECT_WALLET_REQUEST,
  CONNECT_WALLET_SUCCESS,
  GET_WALLET_BALANCE,
  LOGIN_MODAL_CALL,
  SIGNUP_MODAL_CALL,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
} from "../constants/user";
import { connectWallet } from "../web3/walletConnect";
import { getBalance } from "../web3/usdcToken";
import moment from "moment";

export const UserLogin =
  (userData, resetForm, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
        payload: "",
      });
      // const { page, limit } = _data
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/login`,
        userData
      );
      localStorage.setItem("userInfo", JSON.stringify(data?.user));
      localStorage.setItem("token", data?.token);
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data?.user,
      });
      dispatch({
        type: LOGIN_MODAL_CALL,
        payload: false,
      });
      resetForm();
      message.success("Successfully login");
      navigate("/dashboard");
    } catch (error) {
      message.error(
        error?.response?.data?.message || "some thing went wrong!",
        2.5
      );
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error?.response?.data,
      });
    }
  };

export const UserRegister = (userData, resetForm) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
      payload: "",
    });
    // const { page, limit } = _data
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/users/signup`,
      userData
    );
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data?.data,
    });
    dispatch({
      type: SIGNUP_MODAL_CALL,
      payload: false,
    });
    resetForm();
    message.success("Successfully Registerd");
  } catch (error) {
    message.error(
      error?.response?.data?.message || "some thing went wrong!",
      2.5
    );
    dispatch({
      type: USER_REGISTER_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const ConnectWallet =
  (userId, navigate, deal, setIsModalOpen) => async (dispatch) => {
    try {
      dispatch({
        type: CONNECT_WALLET_REQUEST,
        payload: "",
      });
      const signer = await connectWallet();
      if (signer) {
        const walletData = {
          userId,
          wallet: signer,
        };
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/users/connect`,
          data: walletData,
        };
        const { data } = await Axios(config);
        dispatch({
          type: CONNECT_WALLET_SUCCESS,
          payload: data.user,
        });
        const balance = await getBalance(data.user?.wallet);
        dispatch({
          type: GET_WALLET_BALANCE,
          payload: balance,
        });

        message.success(data?.message);
        if (
          deal !== undefined &&
          deal._id &&
          moment().isBefore(deal.dealEndTime) &&
          deal.ownerId._id !== data?.user?._id
        ) {
          setIsModalOpen(false);
          navigate(`/dashboard/deals/${deal._id}`);
        } else if (
          deal !== undefined &&
          deal._id &&
          moment().isBefore(deal.dealEndTime) &&
          deal.ownerId._id === data?.user?._id
        ) {
          setIsModalOpen(false);
          navigate(`/dashboard/deals`);
          message.error("Deal creator cannot join newly created deal");
        } else if (setIsModalOpen) {
          navigate(`/dashboard/deals`);
          setIsModalOpen(false);
        }
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || "some thing went wrong!",
        2.5
      );
      dispatch({
        type: CONNECT_WALLET_FAIL,
        payload: error?.response?.data,
      });
    }
  };

export const logOut = () => (dispatch) => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    dispatch({
      type: USER_LOGOUT,
      payload: "",
    });
    message.success("log out!");
  } catch (error) {
    message.error(
      error?.response?.data?.message || "some thing went wrong!",
      2.5
    );
    dispatch({
      type: CONNECT_WALLET_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const UpdateProfile =
  (userData, resetForm, setIsModalOpen, setFileList, setError) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
        payload: "",
      });
      // const { page, limit } = _data
      const config = {
        method: "PUT",
        url: `${process.env.REACT_APP_BASE_URL}/users/update-profile`,
        data: userData,
      };
      const { data } = await Axios(config);
      dispatch({
        type: USER_UPDATE_PROFILE_SUCCESS,
        payload: data?.user,
      });
      setFileList([]);
      setError(false);
      resetForm();
      setIsModalOpen(false);
      message.success(data?.message);
    } catch (error) {
      message.error(
        error?.response?.data?.message || "some thing went wrong!",
        2.5
      );
      dispatch({
        type: USER_UPDATE_PROFILE_FAIL,
        payload: error?.response?.data,
      });
    }
  };

  export const getUsersList = (setUsersList) => async (dispatch) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST,
        payload: "",
      });

      const config = {
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/users/users-list`,
        data: {},
      };
      const { data } = await Axios(config);
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data?.users,
      });

      setUsersList(data?.users ? data?.users.length : '0')
    } catch (error) {
      message.error(
        error?.response?.data?.message || "some thing went wrong!",
        2.5
      );
      dispatch({
        type: USER_LIST_FAIL,
        payload: error?.response?.data,
      });
    }
  };