import { message } from "antd";
import {
  CREATE_DEAL_FAIL,
  CREATE_DEAL_REQUEST,
  CREATE_DEAL_SUCCESS,
  UPDATE_CLAIM_DEAL_REQUEST,
  // UPDATE_CLAIM_DEAL_SUCCESS,
  GET_DEALS_TOKENS_FAIL,
  GET_DEALS_TOKENS_REQUEST,
  GET_DEALS_TOKENS_SUCCESS,
  GET_DEAL_FAIL,
  GET_DEAL_REQUEST,
  GET_DEAL_SUCCESS,
  GET_FAVORITE_DEALS_FAIL,
  GET_FAVORITE_DEALS_REQUEST,
  GET_FAVORITE_DEALS_SUCCESS,
  GET_PARTICIPATOR_FAIL,
  GET_PARTICIPATOR_REQUEST,
  GET_PARTICIPATOR_SUCCESS,
  JOIN_DEAL_FAIL,
  JOIN_DEAL_REQUEST,
  JOIN_DEAL_SUCCESS,
  UPDATE_FAVORITE_FAIL,
  UPDATE_FAVORITE_REQUEST,
  UPDATE_FAVORITE_SUCCESS,
  UPDATE_CLAIM_DEAL_FAIL,
  GET_CLAIM_DEALS_REQUEST,
  GET_CLAIM_DEALS_SUCCESS,
  GET_CLAIM_DEALS_FAIL,
} from "../constants/deal";
import Axios from "../services/interceptor";
import { claimDeal, createDeal, joinDeal } from "../web3/cryptoDeals";
import { GET_WALLET_BALANCE } from "../constants/user";
import { getBalance } from "../web3/usdcToken";
// import moment from "moment";

export const createDealAPI =
  (dealData, resetForm, setIsModalOpen, setDealsList, setText, setLoader) =>
  async (dispatch) => {
    try {
      setLoader(true);
      dispatch({
        type: CREATE_DEAL_REQUEST,
        payload: "",
      });
      // const { page, limit } = _data
      // const daysInMinutes = moment().minutes(dealData.dealLimit);
      // console.log("minutes ====>", daysInMinutes);
      const maxPrice = Number(dealData.targetPrice) * 10e8;
      const stopLossPrice = Number(dealData.stopLossPrice) * 10e8;
      const deal = await createDeal(
        maxPrice,
        stopLossPrice,
        8,
        dealData.coinAddress,
        dealData.ownerAddress,
        setText
      );
      if (deal.check) {
        // console.log("dealId123 ===>", deal?.dealId);
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/deals/create`,
          data: { ...dealData, dealId: deal?.dealId },
        };
        const { data } = await Axios(config);
        dispatch({
          type: CREATE_DEAL_SUCCESS,
          payload: data?.deal,
        });
        dispatch(getDealsListAPI(setDealsList));
        resetForm();
        setLoader(false);
        setIsModalOpen(false);
        setText("");
        message.success(data?.message);
      } else {
        resetForm();
        setLoader(false);
        setIsModalOpen(false);
        setText("");
        setLoader(false);
        message.error("User reject transaction");
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || "some thing went wrong!",
        2.5
      );
      resetForm();
      setLoader(false);
      setIsModalOpen(false);
      setText("");
      setLoader(false);
      dispatch({
        type: CREATE_DEAL_FAIL,
        payload: error?.response?.data,
      });
    }
  };

export const joinDealAPI =
  (
    dealId,
    userId,
    deal_id,
    tokens,
    setParticipator,
    setText,
    setLoader,
    userWallet
  ) =>
  async (dispatch) => {
    try {
      setLoader(true);
      dispatch({
        type: JOIN_DEAL_REQUEST,
        payload: "",
      });
      const tokenAmount = parseFloat(tokens) * 1e18;
      const deal = await joinDeal(dealId, tokenAmount, setText);
      if (deal) {
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_BASE_URL}/deals/join`,
          data: { dealId: deal_id, userId, tokens },
        };
        const { data } = await Axios(config);
        dispatch({
          type: JOIN_DEAL_SUCCESS,
          payload: data?.user,
        });
        setLoader(false);
        setParticipator(true);
        message.success(data?.message);
        const balance = await getBalance(userWallet);
        dispatch({
          type: GET_WALLET_BALANCE,
          payload: balance,
        });
      } else {
        setText("");
        setLoader(false);
        message.error("User reject transaction");
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || "some thing went wrong!",
        2.5
      );
      setText("");
      setLoader(false);
      dispatch({
        type: JOIN_DEAL_FAIL,
        payload: error?.response?.data,
      });
    }
  };

export const getDealsListAPI = (setDealsList) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DEALS_TOKENS_REQUEST,
      payload: "",
    });
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL}/deals/list`,
      data: {},
    };
    const { data } = await Axios(config);
    dispatch({
      type: GET_DEALS_TOKENS_SUCCESS,
      payload: data?.totalTokens ? data?.totalTokens[0]?.totalTokens : '0',
    });
    setDealsList(data?.deals);
  } catch (error) {
    message.error(
      error?.response?.data?.message || "some thing went wrong!",
      2.5
    );
    dispatch({
      type: GET_DEALS_TOKENS_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const getDeal = (dealId, setDealDetails) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DEAL_REQUEST,
      payload: "",
    });
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/deals/getDeal`,
      data: { dealId },
    };
    const { data } = await Axios(config);
    dispatch({
      type: GET_DEAL_SUCCESS,
      payload: data?.deal,
    });
    setDealDetails(data?.deal);
  } catch (error) {
    message.error(
      error?.response?.data?.message || "some thing went wrong!",
      2.5
    );
    dispatch({
      type: GET_DEAL_FAIL,
      payload: error?.response?.data,
    });
  }
};

export const getDealParticipator =
  (userId, setParticipator, dealId, setDealPrice) => async (dispatch) => {
    try {
      dispatch({
        type: GET_PARTICIPATOR_REQUEST,
        payload: "",
      });
      // console.log(dealId);
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/deals/getParticipator`,
        data: { userId, dealId },
      };
      const { data } = await Axios(config);
      dispatch({
        type: GET_PARTICIPATOR_SUCCESS,
        payload: data?.participator,
      });
      setDealPrice("");
      setParticipator(true);
    } catch (error) {
      dispatch({
        type: GET_PARTICIPATOR_FAIL,
        payload: error?.response?.data,
      });
    }
  };
//get claims deal

export const getClaimDeals = (userId,  setTotalDeals, setTotalDealsWin, setTotalDealsLose) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CLAIM_DEALS_REQUEST,
      payload: "",
    });
    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/deals/claim`,
      data: { userId },
    };
    const { data } = await Axios(config);
    dispatch({
      type: GET_CLAIM_DEALS_SUCCESS,
      payload: data?.calimDeals,
    });
    setTotalDeals(data?.calimDeals);
    setTotalDealsWin(data?.calimDealsWin);
    setTotalDealsLose(data?.calimDealsLose);
    // setTotalTokens(data?.totalTokens);
  } catch (error) {
    dispatch({
      type: GET_CLAIM_DEALS_FAIL,
      payload: error?.response?.data,
    });
  }
};

//update Fav deal

export const updateFavorite =
  (userId, dealId, fav, setDealsList, setFavDeals) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_FAVORITE_REQUEST,
        payload: "",
      });
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/deals/fav`,
        data: { userId, dealId, fav },
      };
      const { data } = await Axios(config);
      dispatch({
        type: UPDATE_FAVORITE_SUCCESS,
        payload: data?.user,
      });
      dispatch(getDealsListAPI(setDealsList));
      dispatch(getFavoriteDealList(userId, setFavDeals));
      message.success("Updated successfully");
    } catch (error) {
      dispatch({
        type: UPDATE_FAVORITE_FAIL,
        payload: error?.response?.data,
      });
    }
  };

//get Fav deal list

export const getFavoriteDealList =
  (userId, setFavDeals) => async (dispatch) => {
    try {
      dispatch({
        type: GET_FAVORITE_DEALS_REQUEST,
        payload: "",
      });
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/deals/favDealList`,
        data: { userId },
      };
      const { data } = await Axios(config);
      dispatch({
        type: GET_FAVORITE_DEALS_SUCCESS,
        payload: data?.favDeals,
      });
      setFavDeals(data?.favDeals);
    } catch (error) {
      dispatch({
        type: GET_FAVORITE_DEALS_FAIL,
        payload: error?.response?.data,
      });
    }
  };

//Claim
export const claim =
  (dealId, dealIndex, setText, setLoader, setDealsList, userWallet) =>
  async (dispatch) => {
    try {
      setLoader(true)
      dispatch({
        type: UPDATE_CLAIM_DEAL_REQUEST,
        payload: "",
      });
      const claimedDeal = await claimDeal(dealId, setText, setLoader);
      if (claimedDeal.check) {
        setLoader(true);
        const config = {
          method: "PUT",
          url: `${process.env.REACT_APP_BASE_URL}/deals/claim/${dealIndex}`,
          data: {status: claimedDeal.result[1]},
        };
         await Axios(config);
        // dispatch({
        //   type: UPDATE_CLAIM_DEAL_SUCCESS,
        //   payload: data?.message,
        // });
        dispatch(getDealsListAPI(setDealsList));
        setText('Expired')
        setLoader(false);
        message.success("claimed Successfully");
        const balance = await getBalance(userWallet);
        dispatch({
          type: GET_WALLET_BALANCE,
          payload: balance,
        });
      } else {
        setText("");
        setLoader(false);
        message.error("User reject transaction");
      }
    } catch (error) {
      setLoader(false);
      setText("");
      message.error(
        error?.response?.data?.message || "some thing went wrong!",
        2.5
      );
      dispatch({
        type: UPDATE_CLAIM_DEAL_FAIL,
        payload: error?.response?.data,
      });
    }
  };
