import React, { useEffect, useState } from "react";
import Classes from "../../scss/Footer.module.scss";
import { Container } from "react-bootstrap";
import Logo from "../../../../assets/images/svg/logo.svg";
import whiteLogo from "../../../../assets/images/svg/whiteLogo.svg";
import { Col, Row } from "antd";
import fb from "../../../../assets/images/svg/fb.svg";
import twitter from "../../../../assets/images/svg/twitterIcon.svg";
import insta from "../../../../assets/images/svg/instaIcon.svg";
import linkedIn from "../../../../assets/images/svg/linkedIn.svg";
import youTube from "../../../../assets/images/svg/youtube.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Footer() {
  const [themeMode, setThemeMode] = useState("");
  const { theme } = useSelector((state) => state.theme);

  useEffect(() => {
    setThemeMode(theme);
  }, [theme]);
  return (
    <footer className={`${Classes.footer}`}>
      <Container>
        <div className="border-bottom pb-4 mb-5">
          {themeMode === "dark-theme" ? (
            <Link to="/">
              <img className={Classes.logo} src={whiteLogo} alt="img" />
            </Link>
          ) : (
            <Link to="/">
              <img className={Classes.logo} src={Logo} alt="img" />
            </Link>
          )}
        </div>
        <Row>
          <Col lg={6} md={24} sm={24} xs={24} className="mb-lg-0 mb-5">
            <p className={`${Classes.txt} text-start`}>Take your crypto to the next level</p>
            <div className="d-flex gap-2">
              <img src={fb} alt="logo" />
              <img src={twitter} alt="logo" />
              <img src={insta} alt="logo" />
              <img src={linkedIn} alt="logo" />
              <img src={youTube} alt="logo" />
            </div>
          </Col>
          <Col lg={4} md={5} sm={5} xs={24}>
            <div className="d-flex justify-content-center flex-column">
              <h3 className={Classes.subTitle}>Company</h3>
              <div>
                <Link className={`${Classes.txt} d-block mb-3`} to="/">
                  About Us
                </Link>
                <Link className={`${Classes.txt} d-block mb-3`} to="/">
                  Blog
                </Link>
                <Link className={`${Classes.txt} d-block mb-3`} to="/">
                  Careers
                </Link>
                <Link className={`${Classes.txt} d-block mb-3`} to="/">
                  Student
                </Link>
                <Link className={`${Classes.txt} d-block mb-3`} to="/">
                  Security
                </Link>
                <Link className={`${Classes.txt} d-block mb-3`} to="/">
                  Trust and Safety
                </Link>
                <Link className={`${Classes.txt} d-block mb-3`} to="/">
                  Newsroom
                </Link>
                <Link className={`${Classes.txt} d-block mb-3`} to="/">
                  Videos
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={3} md={5} sm={5} xs={24}>
          <div className="d-flex justify-content-center flex-column">
            <h3 className={Classes.subTitle}>Learn</h3>
            <div>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                What’s Trending
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Product News
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Events
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                University
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Research
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Market Updates
              </Link>
            </div>
            </div>
          </Col>
          <Col lg={3} md={5} sm={5} xs={24}>
          <div className="d-flex justify-content-center flex-column">
            <h3 className={Classes.subTitle}>Products</h3>
            <div>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Stock & Fund
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Cash Card
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Crypto
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Options
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Gold
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Learn Snacks
              </Link>
            </div>
            </div>
          </Col>
          <Col lg={4} md={5} sm={5} xs={24}>
          <div className="d-flex justify-content-center flex-column">
            <h3 className={Classes.subTitle}>Support</h3>
            <div>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Support Center
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Contact Us
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                System Status
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Areas of Availability
              </Link>
            </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={4} xs={24}>
          <div className="d-flex justify-content-center flex-column">
            <h3 className={Classes.subTitle}>Resources</h3>
            <div>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Prices
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Site Widgets
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Tax
              </Link>
              <Link className={`${Classes.txt} d-block mb-3`} to="/">
                Support
              </Link>
            </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
