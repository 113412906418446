import React, { useRef, useState } from "react";
import Classes from "../../scss/Main/Profile.module.scss";
import editIcon from "../../../../../assets/images/svg/editIcon.svg";
import checkIcon from "../../../../../assets/images/svg/check.svg";
// import addIcon from "../../../../../assets/images/svg/addIcon.svg";
import copyOutline from "../../../../../assets/images/svg/copy-outline.svg";
import { ReactComponent as EyeOff } from "../../../../../assets/images/svg/eyeOff.svg";
import { ReactComponent as Eye } from "../../../../../assets/images/svg/eye.svg";
import gallery from "../../../../../assets/images/svg/gallery.svg";
import profile from "../../../../../assets/images/views/dashboard/profile.svg";
import { Modal, Upload, message } from "antd";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
// import { SignUpSchema } from "../../../../../models/singnUpSchema";
import { UpdateProfile } from "../../../../../_actions/user_actions";

function Profile() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(false);
  const [fileList, setFileList] = useState([]);
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const ref = useRef();

  const handleCancelImg = () => setPreviewOpen(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    resetForm();
    setError(false);
    setFileList([]);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    resetForm();
    setError(false);
    setFileList([]);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || "Preview");
  };
  const handleChangeFile = ({ fileList: newFileList }) =>
    newFileList?.filter((el) => {
      if (
        el?.type === "image/png" ||
        el?.type === "image/jpeg" ||
        el?.type === "image/jpg"
      ) {
        setError(false);
        return setFileList(newFileList);
      } else {
        return setError(true);
      }
    });

  const uploadButton = (
    <div>
      <img
        src={
          userInfo?.user?.profilePicture !== undefined
            ? process.env.REACT_APP_MEDIA_BASE_URL +
              userInfo?.user?.profilePicture
            : gallery
        }
        alt="icon"
      />
    </div>
  );

  const { values, handleChange, handleSubmit, handleBlur, resetForm } =
    useFormik({
      onSubmit: async (values) => {
        const data = {
          userId: userInfo?.user?._id,
          displayName: values.username,
          email: values.email,
          password: values.password,
          profilePicture: fileList.length
            ? await getBase64(fileList[0].originFileObj)
            : "",
          updatedPassword: values.newPassword1,
        };
        if (values.newPassword1 === values.newPassword2) {
          dispatch(
            UpdateProfile(
              data,
              resetForm,
              setIsModalOpen,
              setFileList,
              setError
            )
          );
        } else {
          message.error("Password not matched!");
        }
      },
      initialValues: {
        username: setIsModalOpen ? userInfo.user?.displayName : "",
        email: setIsModalOpen ? userInfo.user?.email : "",
        password: "",
        newPassword1: "",
        newPassword2: "",
      },
      // validationSchema: SignUpSchema,
      validateOnBlur: true,
      validateOnMount: false,
      enableReinitialize: true,
    });

  const HandleDiscard = () => {
    resetForm();
    setError(false);
    setFileList([]);
  };

  const showPassword = (type) => {
    setPasswordType(type);
  };

  setTimeout(() => {
    if (copied) {
      setCopied(false);
    }
  }, 2000);

  return (
    <div className={Classes.profileOuter}>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className={Classes.title}>My Profile</h3>
        <div className={`${Classes.btnOuter} d-flex gap-3`}>
          <button
            onClick={showModal}
            className={`${Classes.editBtn} d-flex gap-2 align-items-center`}
          >
            <img src={editIcon} alt="icon" />
            <span>Edit</span>
          </button>
          {/* <button
            className={`primaryBtn d-flex gap-2 alignn-items-center ${Classes.addBtn}`}
          >
            <img src={addIcon} alt="icon" />
            <span>Create Deal</span>
          </button> */}
        </div>
      </div>
      <div className="py-5">
        <div className="d-flex align-items-center flex-md-row flex-column  gap-3 mb-4">
          <img
            className={Classes.profileImg}
            src={
              userInfo?.user?.profilePicture !== undefined
                ? process.env.REACT_APP_MEDIA_BASE_URL +
                  userInfo?.user?.profilePicture
                : profile
            }
            alt="img"
          />
          <div>
            <h3 className={Classes.name}>{userInfo?.user?.displayName}</h3>
            <div className={Classes.address}>
              {copied ? (
                <img src={checkIcon} alt="icon" />
              ) : (
                <img
                  src={copyOutline}
                  alt="icon"
                  onClick={() => {
                    navigator.clipboard.writeText(userInfo?.user?.wallet);
                    setCopied(true);
                  }}
                />
              )}
              <span>
                {userInfo?.user?.wallet?.substring(0, 15)}...
                {userInfo?.user?.wallet?.substring(
                  userInfo?.user?.wallet?.length,
                  userInfo?.user?.wallet?.length - 4
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <div className="mx-2 mb-4">
            <label className={Classes.label}>Username</label>
            <input
              className={Classes.input}
              value={userInfo?.user?.displayName}
              type="text"
              disabled
            />
          </div>
          <div className="mx-2 mb-4">
            <label className={Classes.label}>Email</label>
            <input
              className={Classes.input}
              value={userInfo?.user?.email}
              type="email"
              disabled
            />
          </div>
          {/* <div className="mx-2 mb-4">
            <label className={Classes.label}>Password</label>
            <div className={`${Classes.input} ${Classes.passwordOuter}`}>
              <input type="password" />
              <img src={eyeOff} alt="icon" />
            </div>
          </div> */}
        </div>
      </div>
      <Modal
        title="Update Profile"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        id="authModal"
        className={Classes.updateModal}
        footer={[
          <div className="d-flex gap-3 px-md-5 px-0">
            <Button
              className={`${Classes.discardBtn} w-100 py-2`}
              onClick={HandleDiscard}
            >
              Discard
            </Button>
            <Button
              className={`${Classes.saveBtn} primaryBtn w-100 py-2`}
              onClick={handleSubmit}
            >
              Save Changes
            </Button>
          </div>,
        ]}
      >
        <div className="px-md-5 px-0 pt-4">
          <div className="d-flex gap-5 align-items-center justify-content-center">
            <div className="d-flex flex-column">
              <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={() => {
                  return false;
                }}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChangeFile}
                onRemove={() => setFileList([])}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              {/* <span className={Classes.uploadtxt}>Upload new picture</span> */}
            </div>
            <Modal
              id="previewModal"
              visible={previewOpen}
              footer={null}
              onCancel={handleCancelImg}
            >
              <img
                alt="img"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                src={previewImage}
              />
            </Modal>
            {error && (
              <span className={Classes.error}>
                Only png, jpg, jpeg files are allowed!
              </span>
            )}
            {/* <img src={profile} alt="img" /> */}
          </div>
          <div className="mt-4">
            <div className="mb-3">
              <label className={Classes.label}>Username</label>
              <input
                className={`${Classes.input} ${Classes.userInput} w-100`}
                placeholder="Enter User Name"
                name="username"
                type="text"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="mb-3">
              <label className={Classes.label}>Email</label>
              <input
                className={`${Classes.input} ${Classes.userInput} w-100`}
                placeholder="Enter Email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled
              />
            </div>
            <div className="mb-3">
              <label className={Classes.label}>Current Password</label>
              <div
                className={`${`${Classes.input} ${Classes.userInput} w-100`} ${
                  Classes.passwordOuter
                }`}
              >
                <input
                  type={passwordType}
                  placeholder="Enter Password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {passwordType === "password" ? (
                  <EyeOff
                    className={Classes.eyeIcon}
                    onClick={() => showPassword("text")}
                  />
                ) : (
                  <Eye onClick={() => showPassword("password")} />
                )}
              </div>
            </div>
            <div className="mb-3">
              <label className={Classes.label}>Enter New Password</label>
              <div
                className={`${`${Classes.input} ${Classes.userInput} w-100`} ${
                  Classes.passwordOuter
                }`}
              >
                <input
                  type={passwordType}
                  placeholder="Enter New Password"
                  name="newPassword1"
                  value={values.newPassword1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {/* {passwordType === 'password' ? <EyeOff className={Classes.eyeIcon} onClick={ () => showPassword('text')} /> : <Eye  onClick={() => showPassword('password')}/> } */}
              </div>
            </div>
            <div className="mb-3">
              <label className={Classes.label}>Re-Enter New Password</label>
              <div
                className={`${`${Classes.input} ${Classes.userInput} w-100`} ${
                  Classes.passwordOuter
                }`}
              >
                <input
                  type={passwordType}
                  placeholder="Re-enter New Password"
                  name="newPassword2"
                  value={values.newPassword2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {/* {passwordType === 'password' ? <EyeOff className={Classes.eyeIcon} onClick={ () => showPassword('text')} /> : <Eye  onClick={() => showPassword('password')}/> } */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Profile;
