import React from 'react'
import Classes from '../../scss/Main/Main.module.scss'
import { Container } from 'react-bootstrap'
import Analytics from './Analytics'
// import DealsDetails from './DealsDetails'
// import History from './History'

function Main() {
  return (
    <div className={Classes.main}>
      <Container>
        <Analytics />
        {/* <History /> */}
        {/* <DealsDetails /> */}
      </Container>
    </div>
  )
}

export default Main