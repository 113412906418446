import { combineReducers } from "redux";
import {
  userLoginReducer,
  userSignUpReducer,
} from './user_reducer';
import { ThemeReducer } from './theme_reducer';
import { dealsReducer } from "./deals_reducer";
import { filtersReducer } from "./filters_reducer";

const rootReducer = combineReducers({
  userInfo: userLoginReducer,
  userSignUp: userSignUpReducer,
  theme: ThemeReducer,
  dealsTokens: dealsReducer,
  filters: filtersReducer
});

export default rootReducer;
