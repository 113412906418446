import React, { useEffect, useState } from "react";
import Classes from "../../scss/Sidebar.module.scss";
import Logo from "../../../../assets/images/svg/logo.svg";
import WhiteLogo from "../../../../assets/images/svg/whiteLogo.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../../../assets/images/views/dashboard/home.svg";
import { ReactComponent as ChartIcon } from "../../../../assets/images/views/dashboard/chart.svg";
// import { ReactComponent as SettingIcon } from "../../../../assets/images/views/dashboard/setting.svg";
// import { ReactComponent as InfoIcon } from "../../../../assets/images/views/dashboard/info.svg";
import { ReactComponent as LogoutIcon } from "../../../../assets/images/views/dashboard/log-out.svg";
import { logOut } from "../../../../_actions/user_actions";
import { useDispatch, useSelector } from "react-redux";
import ToggleDarkMode from "../../../partials/ToggleDarkMode";

function Sidebar() {
  const [activeIndex, setActiveIndex] = useState("dashboard");
  const [themeMode, setThemeMode] = useState("");
  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/deals")) {
      setActiveIndex("deals");
    }
  }, [location]);

  useEffect(() => {
    setThemeMode(theme);
  }, [theme]);

  return (
    <div className={Classes.sidebarWrapper}>
      <div className="d-lg-none d-block">
       <div className="d-flex gap-3 align-items-center mb-4">
        <Link to="/dashboard">
            {themeMode === "dark-theme" ? (
              <img src={WhiteLogo} alt="img" />
            ) : (
              <img src={Logo} alt="img" />
            )}
          </Link>
          <ToggleDarkMode />
       </div>
      </div>
      <div>
        <NavLink
          className={() => {
            return activeIndex === "dashboard"
              ? `${Classes.linkActive} ${Classes.link}`
              : `${Classes.link}`;
          }}
          to="/dashboard"
          onClick={() => setActiveIndex("dashboard")}
        >
          <HomeIcon className={Classes.icon} />
          <span>Performance</span>
        </NavLink>
        <NavLink
          className={() => {
            return activeIndex === "deals"
              ? `${Classes.linkActive} ${Classes.link}`
              : `${Classes.link}`;
          }}
          to="/dashboard/deals"
          onClick={() => setActiveIndex("deals")}
        >
          <ChartIcon className={Classes.icon} />
          <span>Deals</span>
        </NavLink>
        <div className="border-bottom my-3"></div>
        {/* <NavLink
          to=""
          className={() => {
            return activeIndex === "settings"
              ? `${Classes.linkActive} ${Classes.link}`
              : `${Classes.link}`;
          }}
          onClick={() => setActiveIndex("settings")}
        >
          <SettingIcon className={Classes.icon2} />
          <span>Settings</span>
        </NavLink>
        <NavLink
          to=""
          className={() => {
            return activeIndex === "howItWorks"
              ? `${Classes.linkActive} ${Classes.link}`
              : `${Classes.link}`;
          }}
          onClick={() => setActiveIndex("howItWorks")}
        >
          <InfoIcon className={Classes.icon} />
          <span>How it works</span>
        </NavLink> */}
        <NavLink
          to="/"
          className={Classes.link}
          onClick={() => dispatch(logOut())}
        >
          <LogoutIcon className={Classes.icon} />
          <span>Log out</span>
        </NavLink>
      </div>
    </div>
  );
}

export default Sidebar;
