import React, { useEffect, useState } from "react";
import Classes from "../scss/Home/Deals.module.scss";
import { Tabs } from "antd";
import { Container } from "react-bootstrap";
import bar from "../../../assets/images/svg/FilterLines.svg";
import DealCard from "../Common/DealCard";
import { Drawer } from "antd";
import DrawerComponent from "../../partials/Common/DrawerComponent";
import { useDispatch, useSelector } from "react-redux";
import { SIGNUP_MODAL_CALL } from "../../../constants/user";
import moment from "moment";
import { getDealsListAPI } from "../../../_actions/deals_actions";
import {
  RESET_FILTERS,
  SET_CUSTOM_DATES_FILTER,
  SET_ENDS_THIS_MONTH_FILTER,
  SET_ENDS_THIS_WEEK_FILTER,
  SET_ENDS_TODAY_FILTER,
  SET_PERCENTAGE_FILTER,
} from "../../../constants/filters";

function Deals() {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [dealsList, setDealsList] = useState([]);
  const [activeDeals, setActiveDeals] = useState([]);
  const [endingSoonDeals, setEndingDeals] = useState([]);
  const [currentTime, setCurrentTime] = useState(moment());
  const [targetTime, setTargetTime] = useState(moment());

  var ms = moment(targetTime, "DD/MM/YYYY HH:mm:ss").diff(
    moment(currentTime, "DD/MM/YYYY HH:mm:ss")
  );
  var d = moment.duration(ms);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const signUpModalHandler = () => {
    dispatch({
      type: SIGNUP_MODAL_CALL,
      payload: true,
    });
  };

  const onChange = (key) => {
    // console.log(key);
    if (key === "2") {
      const getActiveDeals = dealsList.filter(
        (deal) => deal.participators.length > 0 && 
        moment(new Date()).isBefore(deal.dealEndTime)
      );
      setActiveDeals(getActiveDeals);
    }
    if (key === "3") {
      const getEdingSoonDeals = dealsList.filter((deal) => {
        setTargetTime(moment(deal.dealEndTime));
        return deal.participators.length > 0 && Math.floor(d.asDays()) === 0;
      });
      setEndingDeals(getEdingSoonDeals);
    }
  };

  useEffect(() => {
    dispatch(getDealsListAPI(setDealsList));
  }, [dispatch]);

  const findProfitPercentage = (deal) => {
    const result = (
      [
        (parseFloat(deal?.targetPrice) -
          parseFloat(deal?.entryPrice?.split(" ")[0])) /
          parseFloat(deal?.entryPrice?.split(" ")[0]),
      ] * 100
    ).toFixed(1);
    return result;
  };

  const percentageFilter = () => {
    const dealsWithPercentage = dealsList.map((deal) => {
      const result = findProfitPercentage(deal);
      return { ...deal, profitPercentage: result };
    });
    const filteredWithRange = dealsWithPercentage.filter(
      (deal) =>
        parseInt(deal.profitPercentage) >= parseInt(filters.min) &&
        parseInt(deal.profitPercentage) <= parseInt(filters.max)
    );
    setDealsList(filteredWithRange);
    dispatch({
      type: SET_PERCENTAGE_FILTER,
      payload: false,
    });
  };

  const endTodayFilter = () => {
    const endsToday = dealsList.filter(
      (deal) => moment(deal.dealEndTime).diff(moment(), "days") === 0
    );
    setDealsList(endsToday);
    dispatch({
      type: SET_ENDS_TODAY_FILTER,
      payload: false,
    });
  };

  const endThisWeekFilter = () => {
    const endsThisWeek = dealsList.filter(
      (deal) => moment(deal.dealEndTime).diff(moment(), "days") === 7
    );
    setDealsList(endsThisWeek);
    dispatch({
      type: SET_ENDS_THIS_WEEK_FILTER,
      payload: false,
    });
  };

  const endThisMonthFilter = () => {
    const endsToday = dealsList.filter(
      (deal) => moment(deal.dealEndTime).diff(moment(), "days") >= 30
    );
    setDealsList(endsToday);
    dispatch({
      type: SET_ENDS_THIS_MONTH_FILTER,
      payload: false,
    });
  };

  const customDatesFilter = () => {
    const customDates = dealsList.filter(
      (deal) =>
        moment(deal.dealEndTime) >= moment(filters.min) &&
        moment(deal.dealEndTime) <= moment(filters.max)
    );
    setDealsList(customDates);
    dispatch({
      type: SET_CUSTOM_DATES_FILTER,
      payload: false,
    });
  };

  const filters = useSelector((state) => state.filters);

  useEffect(() => {
    if (filters.percentageFilter) {
      return percentageFilter();
    }
    if (filters.endsTody) {
      return endTodayFilter();
    }
    if (filters.endsThisWeek) {
      return endThisWeekFilter();
    }
    if (filters.endsThisMonth) {
      return endThisMonthFilter();
    }
    if (filters.custom) {
      customDatesFilter();
    }
    if (filters.reset) {
      dispatch(getDealsListAPI(setDealsList));
      dispatch({
        type: RESET_FILTERS,
        payload: false,
      });
    }
  }, [filters]);

  return (
    <div className={Classes.dealsWrapper}>
      <Container className="position-relative">
        <div className="d-flex justify-content-between">
          <Tabs defaultActiveKey="1" onChange={onChange} className="w-100">
            <TabPane tab="Available Deals" key="1">
              <div className={Classes.dealsOuter}>
                <div className={Classes.dealsInner}>
                  {dealsList.filter((deal) =>
                    moment(new Date()).isBefore(deal.dealEndTime)
                  ).length ? (
                    dealsList
                      .filter((deal) =>
                        moment(new Date()).isBefore(deal.dealEndTime)
                      )
                      .reverse()
                      .map((deal) => (
                        <DealCard
                          dealDetail={deal}
                          setDealsList={setDealsList}
                        />
                      ))
                  ) : (
                    <p className="blank-data">No Deals Found...</p>
                  )}
                </div>
                <button
                  className="primaryBtn m-auto d-block"
                  onClick={signUpModalHandler}
                >
                  Sign Up to load more
                </button>
              </div>
            </TabPane>
            <TabPane tab="Trending" key="2">
              <div className={Classes.dealsInner}>
                {activeDeals.length ? (
                  activeDeals.map((deal) => (
                    <DealCard dealDetail={deal} setDealsList={setDealsList} />
                  ))
                ) : (
                  <p className="blank-data">No Deals Found...</p>
                )}
              </div>
            </TabPane>
            <TabPane tab="Ending Soon" key="3">
              <div className={Classes.dealsInner}>
                {endingSoonDeals.length ? (
                  endingSoonDeals.map((deal) => (
                    <DealCard dealDetail={deal} setDealsList={setDealsList} />
                  ))
                ) : (
                  <p className="blank-data">No Deals Found...</p>
                )}
              </div>
            </TabPane>
          </Tabs>
          <button
            onClick={showDrawer}
            className={`${Classes.filter} filterBtn`}
          >
            <img src={bar} alt="icon" />
            <span className="filterTxt">Filters</span>
          </button>
          <Drawer
            title="Filters"
            placement="right"
            onClose={onClose}
            open={open}
          >
            <DrawerComponent />
          </Drawer>
        </div>
      </Container>
    </div>
  );
}

export default Deals;
