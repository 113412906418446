/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import {
  CONNECT_WALLET_SUCCESS,
  DISCONNECT_WALLET,
  GET_WALLET_BALANCE,
  LOGIN_MODAL_CALL,
  SIGNUP_MODAL_CALL,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_UPDATE_PROFILE_SUCCESS,
} from "../constants/user";

export const userLoginReducer = (
  state = {
    loading: false,
    loginModal: false,
    isConnected: false,
    balance: "0",
    user: {},
    err: "",
  },
  action
) => {
  switch (action.type) {
    case LOGIN_MODAL_CALL: {
      return { ...state, loginModal: action.payload };
    }
    case USER_LOGIN_REQUEST: {
      return { ...state, loading: true, err: "" };
    }
    case USER_LOGIN_SUCCESS: {
      return {
        ...state,
        user: action?.payload,
        loading: false,
        err: "",
      };
    }
    case CONNECT_WALLET_SUCCESS: {
      return {
        ...state,
        isConnected: true,
        loading: false,
        user: action.payload,
        err: "",
      };
    }
    case GET_WALLET_BALANCE: {
      return {
        ...state,
        balance: action.payload,
        loading: false,
        err: "",
      };
    }
    case USER_UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        user: action?.payload,
        loading: false,
        err: "",
      };
    }
    case USER_LOGOUT: {
      return {
        ...state,
        user: {},
        isConnected: false,
        balance: "0",
        loading: false,
        err: "",
      };
    }
    case DISCONNECT_WALLET: {
      return {
        ...state,
        isConnected: false,
        balance: "0",
        loading: false,
        err: "",
      };
    }
    case USER_LOGIN_FAIL: {
      return {
        ...state,
        loading: false,
        err: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const userSignUpReducer = (
  state = {
    signUpModal: false,
  },
  action
) => {
  switch (action.type) {
    case SIGNUP_MODAL_CALL: {
      return { ...state, signUpModal: action?.payload };
    }
    default: {
      return state;
    }
  }
};
