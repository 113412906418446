import React, { useEffect, useState } from "react";
import Classes from "../../scss/PortalHeader.module.scss";
import Logo from "../../../../assets/images/svg/logo.svg";
import WhiteLogo from "../../../../assets/images/svg/whiteLogo.svg";
import { Container } from "react-bootstrap";
import { Drawer, Dropdown } from "antd";
import ToggleDarkMode from "../../../partials/ToggleDarkMode";
import { Link, useNavigate } from "react-router-dom";
// import {ReactComponent as SearchIcon} from "../../../../assets/images/svg/searchIcon.svg";
import metamask from "../../../../assets/images/svg/metamask.svg";
import userImg from "../../../../assets/images/svg/userImg.svg";
import copyIcon from "../../../../assets/images/svg/copy-outline.svg";
import checkIcon from "../../../../assets/images/svg/check.svg";
import bar from "../../../../assets/images/svg/FilterLines.svg";
import DrawerComponent from "../../../partials/Common/DrawerComponent";
import dollarIcon from "../../../../assets/images/svg/dollar.svg";
import { ReactComponent as UserIcon } from "../../../../assets/images/svg/user.svg";
import { ReactComponent as LogoutIcon } from "../../../../assets/images/views/dashboard/log-out.svg";
import { useDispatch, useSelector } from "react-redux";
import { ConnectWallet } from "../../../../_actions/user_actions";
import { DISCONNECT_WALLET } from "../../../../constants/user";
import { FaBars } from "react-icons/fa";
import Sidebar from "../Sidebar";

function PortalHeader() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [themeMode, setThemeMode] = useState("");
  const [copied, setCopied] = useState(false);
  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.user);
  const isConnect = useSelector((state) => state.userInfo.isConnected);
  const balance = useSelector((state) => state.userInfo.balance);
  const navigate = useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showDrawer2 = () => {
    setOpen2(true);
  };

  const onClose2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    setThemeMode(theme);
  }, [theme]);

  const disconnect = () => {
    dispatch({
      type: DISCONNECT_WALLET,
      payload: "",
    });
  };
  setTimeout(() => {
    if(copied){
      setCopied(false)
    }
  },2000)

  const items = [
    {
      key: "item-1",
      label: (
        <div>
          <h3 className={Classes.txt}>@{userInfo.displayName}</h3>
          <div className="d-flex align-items-center gap-2 mb-2">
            
            {copied ? (
                <img src={checkIcon} alt="icon" />
              ) : (
                <img
                src={copyIcon}
                alt="icon"
                onClick={() => {
                  navigator.clipboard.writeText(userInfo?.wallet);
                  setCopied(true)
                }}
              />
              )}
            <span className={Classes.address}>
              {userInfo.wallet !== undefined &&
                userInfo?.wallet?.substring(0, 15)}
              ...
              {userInfo?.wallet?.substring(
                userInfo?.wallet?.length,
                userInfo?.wallet?.length - 4
              )}
            </span>
          </div>
          <div className="d-flex align-items-center gap-1">
            <img height={20} src={dollarIcon} alt="icon" />
            <span className={Classes.txt}>{balance} USD</span>
          </div>
        </div>
      ),
    },
    {
      key: "item-2",
      label: (
        <div className={Classes.link}>
          <Link to="/dashboard/profile" className={Classes.dropdown}>
            <div className={Classes.dropdownOuter}>
              <UserIcon className={Classes.userIcon} />
              <div className={Classes.dropdownLink}>My Profile</div>
            </div>
          </Link>
        </div>
      ),
    },
    {
      key: "item-3",
      label: (
        <div className={Classes.link}>
          <Link to="" className={Classes.dropdown} onClick={disconnect}>
            <div className={Classes.dropdownOuter}>
              <LogoutIcon className={Classes.userIcon} />
              <div className={Classes.dropdownLink}>Disconnect</div>
            </div>
          </Link>
        </div>
      ),
    },
  ];
  return (
    <Container>
      <div className={Classes.portalHeaderWrap}>
        <div className="d-flex align-items-center gap-2 d-lg-block d-none">
          <div className="d-flex align-items-center gap-2">
            <Link to="/dashboard">
              {themeMode === "dark-theme" ? (
                <img className={Classes.logo} src={WhiteLogo} alt="img" />
              ) : (
                <img className={Classes.logo} src={Logo} alt="img" />
              )}
            </Link>
            <ToggleDarkMode />
          </div>
        </div>
        <div
          className={`${Classes.headerInfo} d-flex align-items-center gap-lg-5 gap-3`}
        >
          <div className="d-flex gap-3 align-items-center">
            <div className="d-lg-none d-block">
              <div className={Classes.icon} onClick={showDrawer2}>
                <FaBars />
              </div>
            </div>
            {/* <div className={Classes.searchOuter}>
              <SearchIcon className={`${Classes.searchIcon} pe-2`} />
              <img className="pe-2" src={searchIcon} alt="icon" />
              <input placeholder="Search..." type="search" />
            </div> */}
          </div>
          <div className="d-flex gap-3">
            {userInfo && !isConnect ? (
              <button
                className={`${Classes.connectBtn} primaryBtn d-flex gap-3`}
                onClick={() => dispatch(ConnectWallet(userInfo?._id, navigate))}
              >
                <img src={metamask} alt="img" />
                <span>Connect</span>
              </button>
            ) : (
              <Dropdown menu={{ items }}>
                <div onClick={(e) => e.preventDefault()}>
                  <button
                    className={`${Classes.connectBtn} primaryBtn d-flex gap-3`}
                  >
                    <img
                      className={Classes.userImg}
                      src={
                        userInfo?.profilePicture !== undefined
                          ? process.env.REACT_APP_MEDIA_BASE_URL +
                            userInfo?.profilePicture
                          : userImg
                      }
                      alt="img"
                    />
                    <span>
                      {balance} <span className={Classes.green}>USD</span>
                    </span>
                  </button>
                </div>
              </Dropdown>
            )}
            <button
              onClick={showDrawer}
              className={`${Classes.filters} filterBtn`}
            >
              <img src={bar} alt="icon" />
              <span className="filterTxt">Filters</span>
            </button>
          </div>
          <Drawer
            title="Filters"
            placement="right"
            onClose={onClose}
            open={open}
          >
            <DrawerComponent />
          </Drawer>
        </div>
      </div>
      <div>
        <Drawer title="" placement="left" onClose={onClose2} open={open2}>
          <Sidebar />
        </Drawer>
      </div>
    </Container>
  );
}

export default PortalHeader;
