import React, { Suspense } from "react";
import {
  Route,
  Routes,
} from "react-router-dom";
import Home from "./views/Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import PortalPages from "./views/Pages/PortalPages";
import Main from "./views/Pages/PortalPages/Main";
import PortalDeals from "./views/Pages/PortalPages/PortalDeals";
import DealCardDetail from "./views/Pages/PortalPages/DealCardDetail.js";
import { RequireAuth } from "./partials/Common/ProtectedRoute";
import Profile from "./views/Pages/PortalPages/Profile";

function App() {
  return (
    <Suspense fallback={<>Loading...</>}>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="*" element={<>Not found</>} />
        </Route>
        <Route path="/dashboard">
          <Route
            path=""
            element={
              <RequireAuth>
                <PortalPages />
              </RequireAuth>
            }
          >
            <Route
              path=""
              element={
                <RequireAuth>
                  <Main />
                </RequireAuth>
              }
            />
            <Route
              path="deals"
              element={
                <RequireAuth>
                  <PortalDeals />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/dashboard/deals/:id"
              element={
                <RequireAuth>
                  <DealCardDetail />
                </RequireAuth>
              }
            />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;