import React, { useEffect } from "react";
import HeroSection from "../../partials/Home/HeroSection";
import Deals from "../../partials/Home/Deals";
import PredictionMarkets from "../../partials/Home/PredictionMarkets";
import HowItWorks from "../../partials/Home/HowItWorks";
import LandingLayout from "../../layout/LandingLayout";
import ContactUs from "../../partials/Home/ContactUs";
import { useLocation, useNavigate } from "react-router-dom";

function Home() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (token === null) {
      navigate("/");
    } else if (token !== null && location.pathname === "/") {
      navigate("/dashboard");
    }
  }, []);
  return (
    <>
      <LandingLayout>
        <HeroSection />
        <Deals />
        <PredictionMarkets />
        <HowItWorks />
        <ContactUs />
      </LandingLayout>
    </>
  );
}

export default Home;
