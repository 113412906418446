// ** Redux, Thunk & Root Reducer Imports
import thunk from "redux-thunk";
import rootReducer from "../_reducers/index";
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";

// ** init middleware
const middleware = [thunk];

function saveToLocalStorage(state) {
  const serializedState = JSON.stringify(state);
  localStorage.setItem("state", serializedState);
}

function loadFromLocalStorage() {
  const serializedState = localStorage.getItem("state");
  if (serializedState === null) return undefined;
  return JSON.parse(serializedState);
}
// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const presistedState = loadFromLocalStorage();

// ** Create store
const store = createStore(
  rootReducer,
  presistedState,
  composeEnhancers(applyMiddleware(...middleware))
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export { store };
