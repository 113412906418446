import { useLocation, Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

export function RequireAuth({ children }) {
  const token = localStorage.getItem("token");
  let location = useLocation();
  let decodedToken = "";
  console.log("Decoded Token", decodedToken);
  let currentDate = new Date();
  if (token !== null) {
    decodedToken = jwt_decode(token);
  }

  if (token === null || decodedToken.exp * 1000 < currentDate.getTime()) {
    localStorage.removeItem('token')
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
}
