import { message } from "antd";
// import axios from "axios";

// export const getMaticInUSDC = async () => {
//   const { data } = await axios.get(
//     "https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USDC"
//   );
//   return data.USDC;
// };

export const connectWallet = async () => {
  try {
    if (window.ethereum) {
      const account = await window.ethereum
        .request({
          method: "wallet_requestPermissions",
          params: [
            {
              eth_accounts: {},
            },
          ],
        })
        .then(() =>
          window.ethereum.request({
            method: "eth_requestAccounts",
          })
        );
      return account[0];
    } else {
      window.open("https://metamask.io/download/", "_blank");
      message.warning("Make sure you have coinbase installed.");
    }
  } catch (error) {
    message.error(error.message);
  }
};

// export const getBalance = async () => {
//   if (window.ethereum) {
//     const provider = new ethers.BrowserProvider(window.ethereum);
//     const signer = await provider.getSigner();
//     const balance = await provider.getBalance(signer.address);

//     // const balanceInUSDC = new BigNumber(balance).div(10 ** decimals);
//     const MATIC_IN_USDC = await getMaticInUSDC();
//     const balanceInMatic = (
//       parseFloat(ethers.formatEther(balance)) * MATIC_IN_USDC
//     ).toFixed(4);
//     // const balanceInUSDC =( parseFloat(balanceInMatic) / 1000000).toFixe
//     return balanceInMatic;
//   }
// };
