export const CREATE_DEAL_REQUEST = "CREATE_DEAL_REQUEST";
export const CREATE_DEAL_SUCCESS = "CREATE_DEAL_SUCCESS";
export const CREATE_DEAL_FAIL = "CREATE_DEAL_FAIL";

export const JOIN_DEAL_REQUEST = "JOIN_DEAL_REQUEST";
export const JOIN_DEAL_SUCCESS = "JOIN_DEAL_SUCCESS";
export const JOIN_DEAL_FAIL = "JOIN_DEAL_FAIL";

export const GET_DEALS_TOKENS_REQUEST = "GET_DEALS_TOKENS_REQUEST";
export const GET_DEALS_TOKENS_SUCCESS = "GET_DEALS_TOKENS_SUCCESS";
export const GET_DEALS_TOKENS_FAIL = "GET_DEALS_TOKENS_FAIL";

export const GET_PARTICIPATOR_REQUEST = "GET_PARTICIPATOR_REQUEST";
export const GET_PARTICIPATOR_SUCCESS = "GET_PARTICIPATOR_SUCCESS";
export const GET_PARTICIPATOR_FAIL = "GET_PARTICIPATOR_FAIL";

export const GET_DEAL_REQUEST = "GET_DEAL_REQUEST";
export const GET_DEAL_SUCCESS = "GET_DEAL_SUCCESS";
export const GET_DEAL_FAIL = "GET_DEAL_FAIL";

export const UPDATE_FAVORITE_REQUEST = "UPDATE_FAVORITE_REQUEST";
export const UPDATE_FAVORITE_SUCCESS = "UPDATE_FAVORITE_SUCCESS";
export const UPDATE_FAVORITE_FAIL = "UPDATE_FAVORITE_FAIL";

export const GET_FAVORITE_DEALS_REQUEST = "GET_FAVORITE_DEALS_REQUEST";
export const GET_FAVORITE_DEALS_SUCCESS = "GET_FAVORITE_DEALS_SUCCESS";
export const GET_FAVORITE_DEALS_FAIL = "GET_FAVORITE_DEALS_FAIL";

export const UPDATE_CLAIM_DEAL_REQUEST = "UPDATE_CLAIM_DEAL_REQUEST";
export const UPDATE_CLAIM_DEAL_SUCCESS = "UPDATE_CLAIM_DEAL_SUCCESS";
export const UPDATE_CLAIM_DEAL_FAIL = "UPDATE_CLAIM_DEAL_FAIL";

export const GET_CLAIM_DEALS_REQUEST = "GET_CLAIM_DEALS_REQUEST";
export const GET_CLAIM_DEALS_SUCCESS = "GET_CLAIM_DEALS_SUCCESS";
export const GET_CLAIM_DEALS_FAIL = "GET_CLAIM_DEALS_FAIL";