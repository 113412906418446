import CONTRACT_ABI from "../contract/cryptoDealsContract.json";
import { ethers } from "ethers";
import { approveToken } from "./usdcToken";

const contractAddress = "0x4557a3F994a8263C097114aC20B2d4FC38B94879";

export const getLatestPrice = async (coinAddress) => {
  const { ethereum } = window;
  try {
    if (ethereum) {
      const provider = new ethers.BrowserProvider(ethereum);
      const contract = new ethers.Contract(
        contractAddress,
        CONTRACT_ABI,
        provider
      );
      const coinPrice = await contract.getLatestPrice(coinAddress);
      const maticToUsd = Number(coinPrice) / 1e8;
      return maticToUsd.toFixed(2);
    } else {
      console.log("Ethereum object does not exist");
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const createDeal = async (
  maxAmount,
  stopLoss,
  minutesDeadline,
  coin,
  dealerAddress,
  setText
) => {
  const { ethereum } = window;
  try {
    if (ethereum) {
      const provider = new ethers.BrowserProvider(ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        contractAddress,
        CONTRACT_ABI,
        signer
      );
      console.log("Initialize Payment...");
      setText("Initialize Payment...");
      // console.log(dealerAddress);
      const txn = await contract.createDeal(
        maxAmount,
        stopLoss,
        minutesDeadline,
        coin
      );
      console.log("Creating deal... please wait");
      setText("Creating deal... please wait");
      await txn.wait();
      const deals = await contract.getAllDealsStatus();
      if (deals !== undefined) {
        return {
          check: true,
          dealId: Object.keys(deals).length - 1,
          url: `Created, see transaction: https://mumbai.polygonscan.com/tx/${txn.hash}`,
        };
      }
    } else {
      console.log("Ethereum object does not exist");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

// export const getDealsofDealer = async(dealerAddress) => {
//   const provider = new ethers.BrowserProvider(window.ethereum);
//       const signer = await provider.getSigner();
//       const contract = new ethers.Contract(
//         contractAddress,
//         CONTRACT_ABI,
//         signer
//       );
//  const deals = await contract.getDealsofDealer(dealerAddress)

//       //  console.log('deals ===>',Object.keys(deals).length -1)
//       //  console.log('deals123 ===>',Object.keys(deals).map((deal) => (deals[deal][0])).at(deals.length-1))
// }

export const joinDeal = async (dealId, tokenAmount, setText) => {
  const { ethereum } = window;
  try {
    if (ethereum) {
      const provider = new ethers.BrowserProvider(ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        contractAddress,
        CONTRACT_ABI,
        signer
      );
      console.log("Initialize Payment...");
      setText("Initialize Payment...");
      const approve = await approveToken(
        contractAddress,
        tokenAmount.toString()
      );
      if (approve) {
        const txn = await contract.joinDeal(dealId, tokenAmount.toString());
        console.log("Joining deal... please wait");
        setText("Joining deal... please wait");
        await txn.wait();
        console.log(
          `Deal Joined, see transaction: https://mumbai.polygonscan.com/tx/${txn.hash}`
        );
        return true;
      }
    } else {
      console.log("Ethereum object does not exist");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const claimDeal = async (dealId, setText, setLoader) => {
  const { ethereum } = window;
  try {
    if (ethereum) {
      setLoader(true);
      const provider = new ethers.BrowserProvider(ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        contractAddress,
        CONTRACT_ABI,
        signer
      );
      // console.log(dealId);
      console.log("Initialize Payment...");
      setText("Initialize Payment...");
      const txn = await contract.claim(dealId);
      console.log("Claiming deal... please wait");
      setText("Claiming deal... please wait");

      await txn.wait();
      // Get the transaction receipt
      const transactionReceipt = await provider.getTransactionReceipt(txn.hash);

      // Decode logs using the contract interface
      const decodedLogs = transactionReceipt.logs.map((log) =>
        contract.interface.parseLog(log)
      );

      // console.log(decodedLogs[1].args);

      console.log(
        `Deal claimed, see transaction: https://mumbai.polygonscan.com/tx/${txn.hash}`
      );
      setLoader(false);
      return {
        check: true,
        result: decodedLogs[1].args,
      };
    } else {
      console.log("Ethereum object does not exist");
      setText("");
      setLoader(false);
      return {
        check: false,
        result: "",
      };
    }
  } catch (error) {
    console.log(error);
    setText("");
    setLoader(false);
    return {
      check: false,
      result: "",
    };
  }
};
