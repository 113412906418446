import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Modal } from "antd";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button } from "react-bootstrap";
import Logo from "../../../../assets/images/svg/logo.svg";
import Classes from "../../scss/Header.module.scss";
import { UserLogin, UserRegister } from "../../../../_actions/user_actions";
import { useDispatch, useSelector } from "react-redux";
import { SignUpSchema } from "../../../../models/singnUpSchema";
import { useFormik } from "formik";
import { LogInSchema } from "../../../../models/logInSchema";
import ToggleDarkMode from "../../../partials/ToggleDarkMode";
import { Link, useNavigate } from "react-router-dom";
import {
  LOGIN_MODAL_CALL,
  SIGNUP_MODAL_CALL,
} from "../../../../constants/user";
import whiteLogo from "../../../../assets/images/svg/whiteLogo.svg";
import { ReactComponent as EyeOff } from "../../../../assets/images/svg/eyeOff.svg";
import { ReactComponent as Eye } from "../../../../assets/images/svg/eye.svg";
// import { useSelector } from 'react-redux';

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signUpModal = useSelector((state) => state.userSignUp.signUpModal);
  const loginModal = useSelector((state) => state.userInfo.loginModal);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [themeMode, setThemeMode] = useState("");
  const { theme } = useSelector((state) => state.theme);
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    setThemeMode(theme);
  }, [theme]);
  const {
    dirty,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
    errors,
    isValid,
  } = useFormik({
    onSubmit: async (values) => {
      if (loginModal) {
        const data = {
          email: values.email,
          password: values.password,
        };
        dispatch(UserLogin(data, resetForm, navigate));
      }
      if (signUpModal) {
        const data = {
          displayName: values.username,
          email: values.email,
          password: values.password,
        };
        dispatch(UserRegister(data, resetForm));
      }
    },
    initialValues: {
      username: "",
      email: "",
      password: "",
    },
    validationSchema: signUpModal ? SignUpSchema : LogInSchema,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
  });
  const showPassword = (type) => {
    setPasswordType(type);
  };
  const showModal = () => {
    dispatch({
      type: LOGIN_MODAL_CALL,
      payload: true,
    });
  };
  const showModal2 = () => {
    // setIsModalOpen2(true);
    dispatch({
      type: SIGNUP_MODAL_CALL,
      payload: true,
    });
  };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleOk2 = () => {
  //   setIsModalOpen2(false);
  // };
  const handleCancel = () => {
    dispatch({
      type: LOGIN_MODAL_CALL,
      payload: false,
    });
    resetForm();
  };
  const handleCancel2 = () => {
    // setIsModalOpen2(false);
    dispatch({
      type: SIGNUP_MODAL_CALL,
      payload: false,
    });
    resetForm();
  };
  return (
    <>
      <Navbar expand="lg" className={`${Classes.headerWrapper} w-100`}>
        <Container>
          <div className="d-flex align-items-center">
            <Navbar.Brand>
              {themeMode === "dark-theme" ? (
               <Link to="/"> <img className={Classes.logo} src={whiteLogo} alt="img" /></Link>
              ) : (
                <Link to="/"><img className={Classes.logo} src={Logo} alt="img" /></Link>
              )}
            </Navbar.Brand>
            <ToggleDarkMode />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={`${Classes.linkOuter} m-auto`}>
              <Nav.Link className={`${Classes.navLink}`} href="#home">
                Home
              </Nav.Link>
              <Nav.Link className={`${Classes.navLink}`} href="#howItWorks">
                How it works
              </Nav.Link>
              <Nav.Link className={`${Classes.navLink}`} href="#deals">
                Deals
              </Nav.Link>
            </Nav>
            <div className="d-flex gap-2">
              <button onClick={showModal} className={Classes.baseBtn}>
                Log In
              </button>
              <button onClick={showModal2} className="primaryBtn">
                Sign Up
              </button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        title="Log In"
        open={loginModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        id="authModal"
        footer={[
          <Button
            key="login"
            className="primaryBtn w-100 py-2"
            onClick={handleSubmit}
            disabled={!dirty || !isValid}
          >
            Log In
          </Button>,
        ]}
      >
        <p className={Classes.desc}>
          Log in to your existing crypto deals account to continue your journey.
        </p>
        <div className={Classes.inputOuter}>
          <div className={Classes.mgBotom}>
            <label className="mb-2">
              <b>Enter your Email Address</b>
            </label>
            <input
              className={Classes.input}
              name="email"
              type="email"
              placeholder="Enter email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {errors ? (
              <div className={Classes.error}>{errors.email}</div>
            ) : (
              <></>
            )}
          </div>
          <div className={Classes.mgBotom}>
            <label className="mb-2">
              <b>Enter Password</b>
            </label>
            <div
              className={`${`${Classes.input} ${Classes.userInput} w-100  d-flex`} ${
                Classes.passwordOuter
              }`}
            >
              <input
                name="password"
                type={passwordType}
                placeholder="Enter password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {passwordType === "password" ? (
                <EyeOff
                  className={Classes.eyeIcon}
                  onClick={() => showPassword("text")}
                />
              ) : (
                <Eye onClick={() => showPassword("password")} />
              )}
            </div>

            {errors ? (
              <div className={Classes.error}>{errors.password}</div>
            ) : (
              <></>
            )}
          </div>
          <div className={`${Classes.desc} mb-5`}>
            Don’t have an account?{" "}
            <button
              className={Classes.authBtn}
              onClick={() => {
                showModal2();
                handleCancel();
              }}
            >
              Sign Up
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        title="Sign Up"
        open={signUpModal}
        // onOk={handleOk2}
        onCancel={handleCancel2}
        footer={[
          <Button
            key="login"
            className="primaryBtn w-100 py-2"
            onClick={handleSubmit}
            disabled={!dirty || !isValid}
          >
            Sign Up
          </Button>,
        ]}
      >
        <p className={Classes.desc}>
          Sign up to explore the deals and make profit out of them.
        </p>
        <div className={Classes.inputOuter}>
          <div className={Classes.mgBotom}>
            <label className="mb-2">
              <b>Enter the Username </b>
            </label>
            <input
              className={Classes.input}
              type="text"
              name="username"
              placeholder="Enter username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
            />
            {errors ? (
              <div className={Classes.error}>{errors.username}</div>
            ) : (
              <></>
            )}
          </div>
          <div className={Classes.mgBotom}>
            <label className="mb-2">
              <b>Enter your Email Address</b>
            </label>
            <input
              className={Classes.input}
              type="email"
              name="email"
              placeholder="Enter email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {errors ? (
              <div className={Classes.error}>{errors.email}</div>
            ) : (
              <></>
            )}
          </div>
          <div className={Classes.mgBotom}>
            <label className="mb-2">
              <b>Enter Password</b>
            </label>
            <div
              className={`${`${Classes.input} ${Classes.userInput} w-100  d-flex`} ${
                Classes.passwordOuter
              }`}
            >
              <input
                name="password"
                type={passwordType}
                placeholder="Enter password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {passwordType === "password" ? (
                <EyeOff
                  className={Classes.eyeIcon}
                  onClick={() => showPassword("text")}
                />
              ) : (
                <Eye onClick={() => showPassword("password")} />
              )}
            </div>
            {errors ? (
              <div className={Classes.error}>{errors.password}</div>
            ) : (
              <></>
            )}
          </div>
          <div className={`${Classes.desc} mb-3`}>
            Already have an account?
            <button
              className={Classes.authBtn}
              onClick={() => {
                showModal();
                handleCancel2();
              }}
            >
              Login Instead
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Header;
