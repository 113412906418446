import React from 'react';
import { Outlet } from 'react-router-dom';
import Classess from '../scss/PortalPages.module.scss';
import Sidebar from '../../../layout/PortalLayout/Sidebar';
import PortalHeader from '../../../layout/PortalLayout/PortalHeader';

function PortalPages() {
  return (
    <div className={`${Classess.wrap}`}>
      <div className={`${Classess.sidebar} d-none d-lg-block`}>
        <Sidebar />
      </div>
      <div className={`${Classess.content}`}>
        <div>
          <PortalHeader />
          <div className={`${Classess.outer} container w-100`}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortalPages;
