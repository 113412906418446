/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import { GET_DEALS_TOKENS_FAIL, GET_DEALS_TOKENS_REQUEST, GET_DEALS_TOKENS_SUCCESS } from "../constants/deal";

export const dealsReducer = (
  state = {
    totalTokens: "0",
    loading: false,
    err: ''
  },
  action
) => {
  switch (action.type) {
    case GET_DEALS_TOKENS_REQUEST: {
      return { ...state, loading:true, err: ''};
    }
    case GET_DEALS_TOKENS_SUCCESS: {
      return { ...state, loading:false, err: '', totalTokens: action.payload};
    }
    case GET_DEALS_TOKENS_FAIL: {
      return { ...state, loading:false, err: action.payload};
    }
    default: {
      return state;
    }
  }
};
