import React, { useEffect, useState } from 'react'
import Classes from '../../scss/Main/Analytics.module.scss'
import { Col, Row } from 'react-bootstrap'
import AnalyticImg from '../../../../../assets/images/views/dashboard/analytic1.svg'
import AnalyticImg2 from '../../../../../assets/images/views/dashboard/analytic2.svg'
import greenMask from '../../../../../assets/images/views/dashboard/greenmask.svg'
import redMask from '../../../../../assets/images/views/dashboard/redMask.svg'
import { getClaimDeals } from '../../../../../_actions/deals_actions'
import { useDispatch, useSelector } from 'react-redux'
// import { getUsersList } from '../../../../../_actions/user_actions'
// import greenArrow from '../../../../../assets/images/views/dashboard/greenArrow.svg'
// import redArrow from '../../../../../assets/images/views/dashboard/redArrow.svg'

function Analytics() {

    const [totalDeals, setTotalDeals] = useState('0');
    const [totalDealsWin, setTotalDealsWin] = useState('0');
    const [totalDealsLose, setTotalDealsLose] = useState('0');
    // const [totalTokens, setTotalTokens] = useState('0');
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo)
    // const dealsTokens = useSelector((state) => state.dealsTokens.totalTokens)
    // const [totalUsers, setTotalUsers] = useState('0')

    useEffect(() => {
        // dispatch(getDealsListAPI(setDealsList));
        // dispatch(getUsersList(setTotalUsers))
        dispatch(getClaimDeals(userInfo?.user?._id,setTotalDeals,setTotalDealsWin, setTotalDealsLose));
    },[dispatch])

  return (
    <div className={Classes.analyticWrap}>
        <div>
            <Row>
                {/* <Col lg={4} md={12} sm={12} xs={12}>
                    <div className={`${Classes.cardBg} mb-lg-0 mb-md-5 mb-3`}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-2 align-items-center'>
                                <img src={AnalyticImg} alt="icon"/>
                                <h3 className={Classes.subTitle}>Total Tokens Balance</h3>
                            </div>
                            <img className={Classes.maskImg} src={greenMask} alt="img" />
                        </div>
                        <h1 className={Classes.amount}>${totalTokens !== undefined ? parseFloat(totalTokens).toFixed(2) : '0'}</h1>
                        <div className='d-flex gap-2'>
                            <img src={greenArrow} alt="icon" />
                            <span className={Classes.subTxt}>+35,74%</span>
                        </div>
                    </div>
                </Col> */}
                <Col lg={4} md={12} sm={12} xs={12}>
                <div className={`${Classes.cardBg} mb-lg-0 mb-md-5 mb-3`}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-2 align-items-center'>
                                <img src={AnalyticImg2} alt="icon"/>
                                <h3 className={Classes.subTitle}>Total Deals</h3>
                            </div>
                            <img className={Classes.maskImg} src={greenMask} alt="img" />
                        </div>
                        <h1 className={Classes.amount}>{totalDeals}</h1>
                        {/* <div className='d-flex gap-2'>
                            <img src={redArrow} alt="icon" />
                            <span className={Classes.redsubTxt}>+35,74%</span>
                        </div> */}
                    </div>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12}>
                <div className={`${Classes.cardBg}`}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-2 align-items-center'>
                                <img src={AnalyticImg} alt="icon"/>
                                <h3 className={Classes.subTitle}>Total Deals Win</h3>
                            </div>
                            <img className={Classes.maskImg} src={greenMask} alt="img" />
                        </div>
                        <h1 className={Classes.amount}>{totalDealsWin}</h1>
                        {/* <div className='d-flex gap-2'>
                            <img src={greenArrow} alt="icon" />
                            <span className={Classes.subTxt}>+35,74%</span>
                        </div> */}
                    </div>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12}>
                <div className={`${Classes.cardBg}`}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-2 align-items-center'>
                                <img src={AnalyticImg} alt="icon"/>
                                <h3 className={Classes.subTitle}>Total Deals Lose</h3>
                            </div>
                            <img className={Classes.maskImg} src={redMask} alt="img" />
                        </div>
                        <h1 className={Classes.amount}>{totalDealsLose}</h1>
                        {/* <div className='d-flex gap-2'>
                            <img src={greenArrow} alt="icon" />
                            <span className={Classes.subTxt}>+35,74%</span>
                        </div> */}
                    </div>
                </Col>
            </Row>
            <br></br>
        </div>
    </div>
  )
}

export default Analytics