import * as Yup from "yup";

const onlyNumber = /^[0-9]+$/;

export const contactUsSchema = Yup.object().shape({
  fname: Yup.string().trim().required('Frist name is a required field'),
  lname: Yup.string().trim().required('Last name is a required field'),
  email: Yup.string().required('Email is a required field').email("Invalid email format"),
  phoneNumber: Yup.string()
    .trim()
    .required('Phone number is a required field')
    .matches(onlyNumber, "Phone number is not valid"),
  message: Yup.string().trim().required('Message is a required field'),
});
