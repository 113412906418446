import React from 'react';
import { Row, Col } from 'antd';
import Header from './Header/index.js';
import Footer from './Footer/index.js';

function LandingLayout({children}) {
  return (
    <Row>
      <Col
        span={24}
        xl={24}
        xxl={24}
        className='min-vh-100 d-flex flex-column justify-content-between'
      >
          <div>
            <Header />
          <main>
            {children}
          </main>
        </div>
        <Footer />
      </Col>
    </Row>
  );
}

export default LandingLayout;
