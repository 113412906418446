import React from "react";
import Classes from "../scss/Home/ContactUs.module.scss";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import phoneIcon from "../../../assets/images/svg/phone.svg";
import emailIcon from "../../../assets/images/svg/email.svg";
import locationIcon from "../../../assets/images/svg/location.svg";
import twitterIcon from "../../../assets/images/svg/twitter.svg";
import instaIcon from "../../../assets/images/svg/insta.svg";
import dribbleIcon from "../../../assets/images/svg/dribble.svg";
import bubble from "../../../assets/images/svg/bubble.svg";
// import { Checkbox } from 'antd';
import { useFormik } from "formik";
import { contactUsSchema } from "../../../models/contactUsSchema";

function ContactUs() {
  const {
    isValid,
    dirty,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
  } = useFormik({
    onSubmit: async (values) => {
      const data = {
        firstName: values.fname,
        lastName: values.lname,
        email: values.email,
        phone: values.phoneNumber,
        message: values.message,
      };
      console.log(data);
      resetForm();
    },
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: contactUsSchema,
    validateOnBlur: true,
    validateOnMount: false,
    enableReinitialize: true,
  });
  return (
    <div className={Classes.contactWrapper}>
      <Container>
        <h1 className={Classes.title}>Contact Us</h1>
        <div className={`${Classes.bg} p-2`}>
          <Row>
            <Col md={10}>
              <div className={Classes.leftSection}>
                <div className={Classes.content}>
                  <h3>Contact Information</h3>
                  <p>Let us know about any of your queries</p>
                </div>
                <div className={Classes.metaInfo}>
                  <div className={Classes.info}>
                    <img src={phoneIcon} alt="icon" />
                    <span>+1012 3456 789</span>
                  </div>
                  <div className={Classes.info}>
                    <img src={emailIcon} alt="icon" />
                    <span>demo@gmail.com</span>
                  </div>
                  <div className={Classes.info}>
                    <img src={locationIcon} alt="icon" />
                    <span>
                      132 Dartmouth Street Boston, Massachusetts 02156 United
                      States
                    </span>
                  </div>
                </div>
                <div className={Classes.bottomLink}>
                  <img src={twitterIcon} alt="icon" />
                  <img src={instaIcon} alt="icon" />
                  <img src={dribbleIcon} alt="icon" />
                </div>
                <img className={Classes.bubble} src={bubble} alt="img" />
              </div>
            </Col>
            <Col md={14}>
              <div className={Classes.rightSection}>
                <Row className="justify-content-between">
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="mb-5">
                      <label className={`${Classes.label} d-block mb-2`}>
                        First Name
                      </label>
                      <input
                        type="text"
                        name="fname"
                        className={Classes.input}
                        placeholder="write here..."
                        value={values.fname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors ? (
                        <div className={Classes.error}>{errors.fname}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="mb-5">
                      <label className={`${Classes.label} d-block mb-2`}>
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lname"
                        className={Classes.input}
                        placeholder="write here..."
                        value={values.lname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors ? (
                        <div className={Classes.error}>{errors.lname}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="mb-5">
                      <label className={`${Classes.label} d-block mb-2`}>
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        className={Classes.input}
                        placeholder="write here..."
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors ? (
                        <div className={Classes.error}>{errors.email}</div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div className="mb-5">
                      <label className={`${Classes.label} d-block mb-2`}>
                        Phone Number
                      </label>
                      <input
                        type="text"
                        name="phoneNumber"
                        className={Classes.input}
                        placeholder="write here..."
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors ? (
                        <div className={Classes.error}>
                          {errors.phoneNumber}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                </Row>
                <div>
                  {/* <h3 className={Classes.checkLabel}>Select Subject?</h3> */}
                  {/* <div>
                      <Checkbox><span className={Classes.checkTxt}>General Inquiry</span></Checkbox>
                      <Checkbox><span className={Classes.checkTxt}>General Inquiry</span></Checkbox>
                      <Checkbox><span className={Classes.checkTxt}>General Inquiry</span></Checkbox>
                      <Checkbox><span className={Classes.checkTxt}>General Inquiry</span></Checkbox>
                  </div> */}
                  <div className="my-lg-5 my-3">
                    <label className={`${Classes.label} d-block mb-2`}>
                      Message
                    </label>
                    <textarea
                      type="text"
                      name="message"
                      className={`${Classes.input} w-100`}
                      placeholder="write your message..."
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors ? (
                      <div className={Classes.error}>{errors.message}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="d-flex justify-content-end mt-lg-0 mt-4">
                    <button
                      className={isValid && dirty ? "primaryBtn" : "disableBtn"}
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!dirty}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default ContactUs;
