import React, { useEffect, useState } from "react";
import Classes from "../scss/Home/PredictionMarkets.module.scss";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import img1 from "../../../assets/images/views/Home/img1.svg";
import img2 from "../../../assets/images/views/Home/img2.svg";
import img3 from "../../../assets/images/views/Home/img3.svg";
import darkimg1 from "../../../assets/images/views/Home/darkImg1.svg";
import darkimg2 from "../../../assets/images/views/Home/darkImg2.svg";
import darkimg3 from "../../../assets/images/views/Home/darkImg3.svg";

function PredictionMarkets() {
  const [themeMode, setThemeMode] = useState("");
  const { theme } = useSelector((state) => state.theme);

  useEffect(() => {
    setThemeMode(theme);
  }, [theme]);
  return (
    <div className={Classes.predictionWrapper}>
      <Container>
        <div className="d-flex justify-content-center flex-column align-items-center">
          <h1 className={Classes.title}>
            What are prediction markets?How do we make the difference?
          </h1>
          <p className={Classes.desc}>
            Prediction markets are platforms where people can buy/sell shares on
            the likelihood of an event happening, like who will be winning on
            this sports game. Because participants are incentivised to be right,
            they are valuable tools for forecasting and delivering actionable
            insights.
          </p>
        </div>
        <Row>
          <Col md={8}>
            <div>
              {themeMode === "dark-theme" ? (
                <img className={`${Classes.image} mb-4`} src={darkimg1} alt="img" />
              ) : (
                <img className={`${Classes.image} mb-4`} src={img1} alt="img" />
              )}
              <h5 className={Classes.subTittle}>User Friendly Interface</h5>
              <p className={Classes.subDesc}>
                Our intuitive and user-friendly interface makes navigating the
                platform a breeze. Whether you're a seasoned trader or a novice
                investor, CryptoDeals provides a seamless experience for
                everyone.
              </p>
            </div>
          </Col>
          <Col md={8}>
            <div>
              {themeMode === "dark-theme" ? (
                <img className={`${Classes.image} mb-4`} src={darkimg2} alt="img" />
              ) : (
                <img className={`${Classes.image} mb-4`} src={img2} alt="img" />
              )}
              <h5 className={Classes.subTittle}>Providing Best Deals </h5>
              <p className={Classes.subDesc}>
                Our intuitive and user-friendly interface makes navigating the
                platform a breeze. Whether you're a seasoned trader or a novice
                investor, CryptoDeals provides a seamless experience for
                everyone.
              </p>
            </div>
          </Col>
          <Col md={8}>
            <div>
              {themeMode === "dark-theme" ? (
                <img className={`${Classes.image} mb-4`} src={darkimg3} alt="img" />
              ) : (
                <img className={`${Classes.image} mb-4`} src={img3} alt="img" />
              )}
              <h5 className={Classes.subTittle}>Making deals with freedom</h5>
              <p className={Classes.subDesc}>
                Our intuitive and user-friendly interface makes navigating the
                platform a breeze. Whether you're a seasoned trader or a novice
                investor, CryptoDeals provides a seamless experience for
                everyone.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PredictionMarkets;
