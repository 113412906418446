import React, { useEffect, useState } from "react";
import Classes from "../../scss/Main/DealCardDetail.module.scss";
import { Col, Row } from "react-bootstrap";
import icon from "../../../../../assets/images/svg/back.svg";
// import dealImg from "../../../../../assets/images/svg/dealBigImg.svg";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDeal,
  getDealParticipator,
  joinDealAPI,
} from "../../../../../_actions/deals_actions";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import { message } from "antd";


function DealCardDetail() {
  const [dealPrice, setDealPrice] = useState("");
  const [dealDetails, setDealDetails] = useState("");
  const [currentTime, setCurrentTime] = useState(moment());
  const [targetTime, setTargetTime] = useState(moment());
  const [priceError, setPriceError] = useState("");
  const [participator, setParticipator] = useState(false);
  const [text, setText] = useState("");
  const [loader, setLoader] = useState(false);
  const [profit, setProfit] = useState("");
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.user);
  const userBalance = useSelector((state) => state.userInfo.balance);
  const location = useLocation();
  const joinDealHandler = () => {
    if (currentTime.isBefore(targetTime) && parseFloat(userBalance) > 0) {
      dispatch(
        joinDealAPI(
          Number(dealDetails?.dealId),
          userInfo?._id,
          dealDetails?._id,
          dealPrice,
          setParticipator,
          setText,
          setLoader,
          userInfo?.wallet
        )
      );
    } else if (!currentTime.isBefore(targetTime) && parseFloat(userBalance) > 0) {
      message.error("Deal is expired");
    }else {
      message.error("Insufficient balance");
    }
  };
  useEffect(() => {
    dispatch(getDeal(location.pathname.split("deals/")[1], setDealDetails));
    dispatch(
      getDealParticipator(
        userInfo?._id,
        setParticipator,
        location.pathname.split("deals/")[1],
        setDealPrice
      )
    );
  }, [dispatch, userInfo, location]);
  var ms = moment(targetTime, "DD/MM/YYYY HH:mm:ss").diff(
    moment(currentTime, "DD/MM/YYYY HH:mm:ss")
  );
  var d = moment.duration(ms);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (dealDetails && dealDetails.dealEndTime !== "") {
      setTargetTime(moment(dealDetails.dealEndTime));
    }
  }, [dealDetails]);
  const priceChangeHandler = (value) => {
    if (value !== "0.99") {
      setPriceError("Please enter 0.99 USDC to join deal!");
      setDealPrice(value);
    } else {
      // console.log(value);
      setDealPrice(value);
      setPriceError("");
    }
  };

  const findProfitPercentage = async () => {
    const result = (
      [
        (parseFloat(dealDetails?.targetPrice) - parseFloat(dealDetails?.entryPrice?.split(' ')[0])) /
          parseFloat(dealDetails?.entryPrice?.split(' ')[0]),
      ] * 100
    ).toFixed(1);
    setProfit(result);
  };
  useEffect(() => {
    findProfitPercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d]);
  return (
    <div className={Classes.detailWrapper}>
      <Link to="/dashboard/deals">
        <button className="primaryBtn mb-5">
          <img src={icon} alt="icon" />
          <span>Back</span>
        </button>
      </Link>
      <Row>
        <Col lg={12}>
          <div className={Classes.joinDeal}>
            {/* when deal is not join this div will shows */}
            {!participator ? (
              <div>
                <h3 className={Classes.title}>Join Deal</h3>
                <p className={Classes.desc}>
                  Once your deal is placed, your profit will be decided at the
                  end of time.{" "}
                </p>
                <div className="mt-4">
                  <div className="d-flex gap-2 mb-2">
                    <span>
                      <b>Profit Percentage: </b>
                    </span>
                    <span className={Classes.highlighTxt}>
                      <b>{profit}%</b>
                    </span>
                  </div>
                  <div className="d-flex gap-2 mb-2">
                    <span>
                      <b>Deal Duration: </b>
                    </span>
                    <span className={Classes.highlighTxt}>
                      {Math.floor(d.asDays())} Days
                    </span>
                  </div>
                  <div className="d-flex gap-2 mb-2">
                    <span>
                      <b>Deal Fees:</b>
                    </span>
                    <span className={Classes.highlighTxt}>0.99 USDC</span>
                  </div>
                  <div className="my-4">
                    <label className="mb-2 mt-2">
                      <b>Enter your Price</b>
                    </label>
                    <input
                      type="number"
                      min={1}
                      className={Classes.input}
                      placeholder="Enter your price $"
                      value={dealPrice}
                      onChange={(e) => priceChangeHandler(e.target.value)}
                    />
                    {priceError ? (
                      <div className={Classes.error}>{priceError}</div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="d-flex justify-content-between gap-2  pb-3 mb-4 border-bottom">
                    <span className={Classes.greyTxt}>
                      <b>Available Balance</b>
                    </span>
                    <span className={Classes.highlighTxt}>
                      {" "}
                      ${userBalance}{" "}
                    </span>
                  </div>
                  <button
                    disabled={
                      dealPrice === "" ||
                      priceError !== "" ||
                      text !== "" ||
                      loader
                    }
                    className={
                      dealPrice === "" ||
                      priceError !== "" ||
                      text !== "" ||
                      loader
                        ? "disableBtn w-100 d-flex gap-3 align-items-center justify-content-center"
                        : "primaryBtn w-100 d-flex gap-3 align-items-center justify-content-center"
                    }
                    onClick={joinDealHandler}
                  >
                    {text === "" ? "Join Deal" : text}
                    {loader ? (
                      <Bars
                        height="20"
                        width="20"
                        color="white"
                        ariaLabel="loading"
                        wrapperStyle
                        wrapperClass
                      />
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div>
                {/* when deal is joined this div will shows */}
                <h3 className={Classes.title}>Deal Details</h3>
                <p className={Classes.desc}>
                  Once your deal is placed, your profit will be decided at the
                  end of time.
                </p>
                <div className="mt-4">
                  <div className="d-flex gap-2 mb-2">
                    <span>
                      <b>Token Ticker: </b>
                    </span>
                    <span className={Classes.highlighTxt}>
                      {dealDetails?.coinName}
                    </span>
                  </div>
                  <div className="d-flex gap-2 mb-2">
                    <span>
                      <b>Entry Price: </b>
                    </span>
                    <span className={Classes.highlighTxt}>{dealDetails?.entryPrice}</span>
                  </div>
                  <div className="d-flex gap-2 mb-2">
                    <span>
                      <b>Target Price:</b>
                    </span>
                    <span className={Classes.highlighTxt}>
                      ${dealDetails?.targetPrice}
                    </span>
                  </div>
                  <div className="d-flex gap-2 mb-2">
                    <span>
                      <b>Stop-Loss Price: </b>
                    </span>
                    <span className={Classes.highlighTxt}>
                      ${dealDetails?.stopLossPrice}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default DealCardDetail;
