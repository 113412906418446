import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const axiosRequestHandler = (request) => {
  if (!request.url.includes('login')) {
    const token = localStorage.getItem('token');
    request.headers.token = token;
  }
  return request;
};

const axiosResponseHandler = (response) => {
  return Promise.resolve(response);
};

const axiosErrorHandler = async (error) => {
  // if (error.response && error.response.status === 401) {
  //   const refreshToken = localStorage.getItem('refreshToken');

  //   try {
  //     // Getting new token using refresh token
  //     let request_data = error.response.config;
  //     const response = await axios.post(`${ENV.baseUrl}/auth/refreshToken`, { refreshToken });

  //     // Setting new token
  //     const { data } = response.data;
  //     localStorage.setItem('tokenId', data.id_token);
  //     let headers = {
  //       'Content-Type': 'application/json',
  //       token: data.id_token,
  //     };

  //     // Resend the requested API call
  //     return await axios({
  //       baseURL: ENV.baseUrl,
  //       url: request_data.url,
  //       method: request_data.method,
  //       data: request_data.data,
  //       headers: headers,
  //     });
  //   } catch (tokenErr) {
  //     console.log({ tokenErr });
  //     // if error is not related to Auth simple return
  //     if (tokenErr.response.status !== 401 && !tokenErr.config.url.includes('auth/refreshToken')) return Promise.reject(tokenErr);

  //     localStorage.clear();
  //     window.location = '/login';
  //   }
  // } else if (error.response && error.response.status === 405) {
  //   window.location = '/not-authorized';
  // }

  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

  if (!expectedError) {
    console.log(error);
  }

  return Promise.reject(error);
};

// Intercepting API requests
instance.interceptors.request.use(axiosRequestHandler);

// Intercepting API responses
instance.interceptors.response.use(axiosResponseHandler, axiosErrorHandler);

export default instance;
