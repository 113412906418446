import React, { useEffect, useState } from "react";
import Classes from "../scss/Home/HowItWorks.module.scss";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import how1 from "../../../assets/images/views/Home/how1.svg";
import how2 from "../../../assets/images/views/Home/how2.svg";
import how3 from "../../../assets/images/views/Home/how3.svg";
import how4 from "../../../assets/images/views/Home/how4.svg";
import darkhow1 from "../../../assets/images/views/Home/darkhow1.svg";
import darkhow2 from "../../../assets/images/views/Home/darkhow2.svg";
import darkhow3 from "../../../assets/images/views/Home/darkhow3.svg";
import darkhow4 from "../../../assets/images/views/Home/darkhow4.svg";
import { useSelector } from "react-redux";

function HowItWorks() {
  const [themeMode, setThemeMode] = useState("");
  const { theme } = useSelector((state) => state.theme);

  useEffect(() => {
    setThemeMode(theme);
  }, [theme]);
  return (
    <div className={Classes.wrapper}>
      <Container>
        <h1 className={Classes.title}>How it works?</h1>
        <Row>
          <Col lg={12}>
            <div>
              {themeMode === "dark-theme" ? (
                <img className={`${Classes.image} mb-4`} src={darkhow1} alt="img" />
              ) : (
                <img className={`${Classes.image} mb-4`} src={how1} alt="img" />
              )}
              <div className={`${Classes.outer}  d-flex align-items-center gap-3 mb-3`}>
                <div className={Classes.bullet}>1</div>
                <span className={Classes.subtitle}>Create Account</span>
              </div>
              <p className={Classes.subDesc}>Sign In or Sign Up into the portal and make an account to unlock and unfold the concept of markets.</p>
            </div>
          </Col>
          <Col lg={12}>
            <div>
              {themeMode === "dark-theme" ? (
                <img className={`${Classes.image} mb-4`} src={darkhow2} alt="img" />
              ) : (
                <img className={`${Classes.image} mb-4`} src={how2} alt="img" />
              )}
              <div className={`${Classes.outer}  d-flex align-items-center gap-3 mb-3`}>
                <div className={Classes.bullet}>2</div>
                <span className={Classes.subtitle}>Add Funds</span>
              </div>
              <p className={Classes.subDesc}>To trade, you’ll need to fund your account. If you have a MetaMask account you can easily join your wallet and make a deposit from there.</p>
            </div>
          </Col>
          <Col lg={12}>
            <div>
              {themeMode === "dark-theme" ? (
                <img className={`${Classes.image} mb-4`} src={darkhow3} alt="img" />
              ) : (
                <img className={`${Classes.image} mb-4`} src={how3} alt="img" />
              )}
              <div className={`${Classes.outer}  d-flex align-items-center gap-3 mb-3`}>
                <div className={Classes.bullet}>3</div>
                <span className={Classes.subtitle}>Predict from the deals</span>
              </div>
              <p className={Classes.subDesc}>When you’re ready to make predictions, simply choose an outcome and specify the amount you want to predict for it. </p>
            </div>
          </Col>
          <Col lg={12}>
            <div>
              {themeMode === "dark-theme" ? (
                <img className={`${Classes.image} mb-4`} src={darkhow4} alt="img" />
              ) : (
                <img className={`${Classes.image} mb-4`} src={how4} alt="img" />
              )}
              <div className={`${Classes.outer}  d-flex align-items-center gap-3 mb-3`}>
                <div className={Classes.bullet}>4</div>
                <span className={Classes.subtitle}>Earn if you’re right</span>
              </div>
              <p className={Classes.subDesc}>When the deal ends, each share will either be worth whatever the amount in $ was predicted right and $0 if you predicted wrong.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HowItWorks;
