import React, { useState } from "react";
import { Collapse, Radio, DatePicker } from "antd";
import Classes from "./DrawerComponent.module.scss";
import { useDispatch } from "react-redux";
import {
  RESET_FILTERS,
  SET_CUSTOM_DATES_FILTER,
  SET_ENDS_THIS_MONTH_FILTER,
  SET_ENDS_THIS_WEEK_FILTER,
  SET_ENDS_TODAY_FILTER,
  SET_MIN_AND_MAX,
  SET_PERCENTAGE_FILTER,
} from "../../../../constants/filters";

const { Panel } = Collapse;
function DawerComponent() {
  const [customRadio, setcustomRadio] = useState("");
  const [percentageMin, setPercentageMin] = useState("");
  const [percentageMax, setPercentageMax] = useState("");
  const [dateMin, setDateMin] = useState("");
  const [dateMax, setDateMax] = useState("");

  const dispatch = useDispatch();

  const handleRadioChange = () => {
    dispatch({
      type: RESET_FILTERS,
      payload: true,
    });
    setcustomRadio("");
    setPercentageMin("");
    setPercentageMax("");
    setDateMin("");
    setDateMax("");
  };
  const handleCustomRadio = (e) => {
    setcustomRadio(e.target.value);
    if (e.target.value === "opt1") {
      dispatch({
        type: SET_ENDS_TODAY_FILTER,
        payload: true,
      });
    } else if (e.target.value === "opt2") {
      dispatch({
        type: SET_ENDS_THIS_WEEK_FILTER,
        payload: true,
      });
    } else if (e.target.value === "opt3") {
      dispatch({
        type: SET_ENDS_THIS_MONTH_FILTER,
        payload: true,
      });
    }
  };

  const onChangeMin = (date, dateString) => {
    // console.log(date, dateString);
    setDateMin(dateString);
  };
  const onChangeMax = (date, dateString) => {
    // console.log(date, dateString);
    setDateMax(dateString);
  };

  const percentageFilter = () => {
    dispatch({
      type: SET_PERCENTAGE_FILTER,
      payload: true,
    });
    dispatch({
      type: SET_MIN_AND_MAX,
      payload: { min: percentageMin, max: percentageMax },
    });
  };

  const customDates = () => {
    dispatch({
      type: SET_CUSTOM_DATES_FILTER,
      payload: true,
    });
    dispatch({
      type: SET_MIN_AND_MAX,
      payload: { min: dateMin, max: dateMax },
    });
  };

  return (
    <div className={Classes.drawer}>
      <Collapse defaultActiveKey={1}>
        <Panel header="Maximum Percentage" key="1">
          {/* <div className={Classes.align}>
            <span className={Classes.txt}>Reset</span>
            <Radio
              value="opt1"
              checked={selectedRadio === "opt1"}
              onChange={handleRadioChange}
            ></Radio>
          </div> */}
          <div>
            <span className={`${Classes.txt} mb-2 d-block`}>Custom</span>
            <div className={Classes.inputOuter}>
              <input
                className={Classes.input}
                type="number"
                placeholder="Min"
                min="0"
                value={percentageMin}
                onChange={(e) => setPercentageMin(e.target.value)}
              />
              <span className={Classes.txt}>to</span>
              <input
                className={Classes.input}
                type="number"
                placeholder="Max"
                min="0"
                value={percentageMax}
                onChange={(e) => setPercentageMax(e.target.value)}
              />
            </div>
          </div>
          <button className="primaryBtn w-100" onClick={percentageFilter}>
            Apply
          </button>
        </Panel>
      </Collapse>
      <Collapse>
        <Panel header="End Date" key="2">
          <div className={Classes.align}>
            <span className={Classes.txt}>Ends today</span>
            <Radio
              value="opt1"
              checked={customRadio === "opt1"}
              onChange={handleCustomRadio}
            ></Radio>
          </div>
          <div className={Classes.align}>
            <span className={Classes.txt}>Ends this week</span>
            <Radio
              value="opt2"
              checked={customRadio === "opt2"}
              onChange={handleCustomRadio}
            ></Radio>
          </div>
          <div className={Classes.align}>
            <span className={Classes.txt}>Ends this month</span>
            <Radio
              value="opt3"
              checked={customRadio === "opt3"}
              onChange={handleCustomRadio}
            ></Radio>
          </div>
          <div>
            <span className={`${Classes.txt} mb-2 d-block`}>Custom</span>
            <div className={Classes.inputOuter}>
              <DatePicker className={Classes.input} onChange={onChangeMin} />
              <span className={Classes.txt}>to</span>
              <DatePicker className={Classes.input} onChange={onChangeMax} />
            </div>
          </div>
          <button className="primaryBtn w-100" onClick={customDates}>
            Apply
          </button>
          <button className="primaryBtn w-100 mt-3" onClick={handleRadioChange}>
            Reset
          </button>
        </Panel>
      </Collapse>
    </div>
  );
}

export default DawerComponent;
