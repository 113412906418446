/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import Classes from "./DealCard.module.scss";
import { Modal, message } from "antd";
// import cardImg from "../../../../assets/images/svg/dealimg.svg";
import { ReactComponent as StarIcon } from "../../../../assets/images/svg/rating.svg";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_MODAL_CALL } from "../../../../constants/user";
import { ConnectWallet } from "../../../../_actions/user_actions";
import moment from "moment";
import {
  claim,
  updateFavorite,
} from "../../../../_actions/deals_actions";
import { Bars } from "react-loader-spinner";

function DealCard({ dealDetail, setDealsList, setFavDeals }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(moment());
  const [targetTime, setTargetTime] = useState(moment());
  const [currentItem, setCurrentItem] = useState("");
  const [profit, setProfit] = useState("");
  const [text, setText] = useState("");
  const [loader, setLoader] = useState(false);
  const token = localStorage.getItem("token");
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOwner =
    userInfo !== undefined && dealDetail
      ? userInfo?.user?._id === dealDetail.ownerId?._id
        ? true
        : false
      : false;
  const isParticipator = dealDetail.participators.map((joiners) => {
    if (joiners.userId === userInfo.user._id) {
      return true;
    } else {
      return false;
    }
  });
  const showModal = (e) => {
    e.preventDefault();
    if (token && userInfo.isConnected === false) {
      return setIsModalOpen(true);
    }
    if (token && userInfo?.isConnected && !isOwner) {
      return navigate(`/dashboard/deals/${dealDetail?._id}`);
    } else if (token && userInfo?.isConnected && isOwner) {
      message.error("Deal creators cannot join their own deals");
    } else {
      return dispatch({
        type: LOGIN_MODAL_CALL,
        payload: true,
      });
    }
  };
  const handleOk = (e) => {
    e.preventDefault();
    dispatch(
      ConnectWallet(userInfo?.user?._id, navigate, dealDetail, setIsModalOpen)
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  var ms = moment(targetTime, "DD/MM/YYYY HH:mm:ss").diff(
    moment(currentTime, "DD/MM/YYYY HH:mm:ss")
  );
  var d = moment.duration(ms);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (dealDetail && dealDetail.dealEndTime !== "") {
      setTargetTime(moment(dealDetail.dealEndTime));
    }
  }, [dealDetail]);

  const claimHandler = async () => {
    if (token && userInfo.isConnected === false) {
      return setIsModalOpen(true);
    } else {
      dispatch(
        claim(
          dealDetail?.dealId,
          dealDetail?._id,
          setText,
          setLoader,
          setDealsList,
          userInfo?.user?.wallet
        )
      );
    }
  };

  const findProfitPercentage = async () => {
    const result = (
      [
        (parseFloat(dealDetail?.targetPrice) -
          parseFloat(dealDetail?.entryPrice?.split(" ")[0])) /
          parseFloat(dealDetail?.entryPrice?.split(" ")[0]),
      ] * 100
    ).toFixed(1);
    setProfit(result);
  };

  useEffect(() => {
    findProfitPercentage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d]);

  const favoriteHandler = () => {
    // e.stopPropagation()
    if (Object.keys(userInfo.user).length === 0) {
      return message.error("Please Login/Sign up");
    }
    setCurrentItem(dealDetail);
  };

  useEffect(() => {
    if (userInfo && currentItem === dealDetail) {
      let check =
        userInfo && dealDetail.favoriteBy.includes(userInfo?.user?._id)
          ? true
          : false;
      dispatch(
        updateFavorite(
          userInfo?.user?._id,
          currentItem._id,
          !check,
          setDealsList,
          setFavDeals
        )
      );
    }
  }, [userInfo, dispatch, currentItem, dealDetail, setDealsList, setFavDeals]);

  return (
    <div className={Classes.cardWrapper}>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <div className={Classes.metaInfo}>
            <span className={`${Classes.boldTxt} ${Classes.mainTxt}`}>
              Profit Percentage:
            </span>
            <span className={`${Classes.lightTxt} ${Classes.mainTxt}`}>
              {profit}%
            </span>
          </div>
          <StarIcon
            className={`${
              userInfo &&
              dealDetail &&
              dealDetail.favoriteBy.includes(userInfo?.user?._id)
                ? Classes.starIconActive
                : Classes.starIcon
            }`}
            onClick={favoriteHandler}
          />
        </div>
        <div className={Classes.metaInfo}>
          <span className={Classes.boldTxt}>Created by:</span>
          <span className={Classes.lightTxt}>
            {dealDetail?.ownerId?.displayName}
          </span>
        </div>
        <div className={Classes.metaInfo}>
          <span className={Classes.boldTxt}>Maximum Price:</span>
          <span className={Classes.lightTxt}>${dealDetail?.targetPrice}</span>
        </div>
        <div className={Classes.metaInfo}>
          <span className={Classes.boldTxt}>Expiration In:</span>
          <span className={Classes.lightTxt}>
            {currentTime.isBefore(targetTime)
              ? Math.floor(d.asDays()) + " days"
              : "Expired"}
          </span>
        </div>
        <Modal
          title="Note"
          open={isModalOpen}
          // onOk={handleOk}
          onCancel={handleCancel}
          className={Classes.modalWidth}
          footer={[
            <Button
              className={`${Classes.modalFooter} primaryBtn w-100 py-2`}
              onClick={(e) => handleOk(e)}
            >
              Connect
            </Button>,
          ]}
        >
          <p className={Classes.desc}>
            You need to connect your metamask account in order to create or join
            a deal on the platform.
          </p>
        </Modal>
      </div>
      {(isOwner || isParticipator[0]) && !currentTime.isBefore(targetTime) ? (
        <button
          onClick={claimHandler}
          disabled={text !== ""}
          className={
            text !== ""
              ? "disableBtn w-100 d-flex gap-3 align-items-center justify-content-center mt-3"
              : `primaryBtn w-100 d-flex gap-3 align-items-center justify-content-center mt-3`
          }
        >
          {text === "" ? "Claim" : text}
          {loader ? (
            <Bars
              height="20"
              width="20"
              color="white"
              ariaLabel="loading"
              wrapperStyle
              wrapperClass
            />
          ) : (
            ""
          )}
        </button>
      ) : (
        <button
          onClick={showModal}
          disabled={!currentTime.isBefore(targetTime)}
          className={
            currentTime.isBefore(targetTime)
              ? `${Classes.joinBtn} primaryBtn w-100 mt-3`
              : "disableBtn w-100 mt-3"
          }
        >
          {currentTime.isBefore(targetTime) && isParticipator[0]
            ? "View details"
            : currentTime.isBefore(targetTime) && !isParticipator[0]
            ? "Join deal"
            : "Expired"}
        </button>
      )}
      <Modal
        title="Note"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className={Classes.modalWidth}
        footer={[
          <Button
            className={`${Classes.modalFooter} primaryBtn w-100 py-2`}
            onClick={handleOk}
          >
            Connect
          </Button>,
        ]}
      >
        <p className={Classes.desc}>
          You need to connect your metamask account in order to create or join a
          deal on the platform.
        </p>
      </Modal>
    </div>
  );
}

export default DealCard;
