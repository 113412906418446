import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import { FaMoon, FaSun } from 'react-icons/fa';
import Classes from './scss/ToggleDarkMode.module.scss';
import { useDispatch } from 'react-redux';
import setThemeName from '../../_actions/theme_actions';

const getStorageTheme = () => {
  let theme = 'light-theme';
  if (localStorage.getItem('theme')) {
    theme = localStorage.getItem('theme') || '';
  }
  return theme;
};

function ToggleDarkMode() {
  const dispatch = useDispatch();
  const [theme, setTheme] = useState(getStorageTheme());
  useEffect(() => {
    document.body.className = theme;
    localStorage.setItem('theme', theme);
    dispatch(setThemeName(theme))
  }, [theme]);
  const switchHandler = () => {
    if (theme === 'light-theme') {
      setTheme('dark-theme');
    } else {
      setTheme('light-theme');
    }
  };

  return (
    <div className={Classes.switchWrapper}>
      <Switch
        size="large"
        checkedChildren={<FaMoon fontSize={16} color='#fff' />}
        unCheckedChildren={<FaSun fontSize={16} color='#fff' />}
        onChange={switchHandler}
        checked={theme === 'light-theme'}
      />
    </div>
  );
}

export default ToggleDarkMode;
